/* You can add global styles to this file, and also import other style files */

/**======================================================================
=========================================================================
Template Name: Gradient Able Admin Template
Author: Codedscss/themes
supports: https://codedscss/themes.com/submit-ticket/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */

// Theme Font
@import 'scss/themes/font/theme-font';

// main framework
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'scss/settings/custom-variables';
@import 'scss/settings/theme-variables';

// bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/utilities/api';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import 'scss/themes/general';
@import 'scss/themes/generic';
@import 'scss/themes/minixs/function';
@import 'node_modules/bootstrap-icons/font/bootstrap-icons.css';

// icon
@import 'scss/themes/font/datta-icon';
@import 'scss/themes/font/feather';
@import 'scss/themes/font/fontawesome';
@import 'scss/themes/font/flaticon';

// component
@import 'scss/themes/component/component';

// theme layouts
@import 'scss/themes/layouts/menu-lite';
@import 'scss/themes/layouts/menu-ng';

// dashboard
@import 'scss/themes/pages/dashboard.scss';
@import 'scss/themes/pages/authentication';

@import 'scss/themes/chat/chat';

// custom
@import 'scss/themes/custom';

// AG Grid
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

/* ngx country drop down list CSS */
@import  "node_modules/ngx-countries-dropdown/assets/styles.css";

/* Angular Slickgrid CSS */
@import '@slickgrid-universal/common/dist/styles/css/slickgrid-theme-bootstrap.css';

.transparent-modal .modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.ag-theme-quartz {
  // --ag-foreground-color: rgb(126, 46, 132);
  // --ag-background-color: rgb(249, 245, 227);
  // --ag-header-foreground-color: rgb(204, 245, 172);
  // --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  // --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  // --ag-font-size: 17px;
  // --ag-font-family: monospace;
}
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif !important; }
