@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/**======================================================================
=========================================================================
Template Name: Gradient Able Admin Template
Author: Codedscss/themes
supports: https://codedscss/themes.com/submit-ticket/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
/* devanagari */
/*@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(assets/fonts/poppins/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}*/
/* latin-ext */
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import 'node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import "node_modules/ngx-countries-dropdown/assets/styles.css";
@import '@slickgrid-universal/common/dist/styles/css/slickgrid-theme-bootstrap.css';
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(./../../../assets/fonts/poppins/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(./../../../assets/fonts/poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(./../../../assets/fonts/poppins/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(./../../../assets/fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(./../../../assets/fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(./../../../assets/fonts/poppins/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(./../../../assets/fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(./../../../assets/fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*!
 * Bootstrap  v5.3.0 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #4099ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #ff5370;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffb64d;
  --bs-green: #2ed8b6;
  --bs-teal: #20c997;
  --bs-cyan: #00bcd4;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4099ff;
  --bs-secondary: #6c757d;
  --bs-success: #2ed8b6;
  --bs-info: #00bcd4;
  --bs-warning: #ffb64d;
  --bs-danger: #ff5370;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-primary-rgb: 64, 153, 255;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 46, 216, 182;
  --bs-info-rgb: 0, 188, 212;
  --bs-warning-rgb: 255, 182, 77;
  --bs-danger-rgb: 255, 83, 112;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 52, 58, 64;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Poppins", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #222;
  --bs-body-color-rgb: 34, 34, 34;
  --bs-body-bg: #f6f7fb;
  --bs-body-bg-rgb: 246, 247, 251;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #4099ff;
  --bs-link-color-rgb: 64, 153, 255;
  --bs-link-decoration: none;
  --bs-link-hover-color: #4099ff;
  --bs-link-hover-color-rgb: 64, 153, 255;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fcf8e3;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e3eaef;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 5px;
  --bs-border-radius-sm: 5px;
  --bs-border-radius-lg: 5px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #8cc2ff;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #82e8d3;
  --bs-info-text-emphasis: #66d7e5;
  --bs-warning-text-emphasis: #ffd394;
  --bs-danger-text-emphasis: #ff98a9;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #0d1f33;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #092b24;
  --bs-info-bg-subtle: #00262a;
  --bs-warning-bg-subtle: #33240f;
  --bs-danger-bg-subtle: #331116;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #265c99;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #1c826d;
  --bs-info-border-subtle: #00717f;
  --bs-warning-border-subtle: #996d2e;
  --bs-danger-border-subtle: #993243;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #8cc2ff;
  --bs-link-hover-color: #a3ceff;
  --bs-link-color-rgb: 140, 194, 255;
  --bs-link-hover-color-rgb: 163, 206, 255;
  --bs-code-color: #f18bba;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 80%;
}

mark, .mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 87.5%;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 5px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f6f7fb;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #222;
  --bs-table-bg: transparent;
  --bs-table-border-color: #e3eaef;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #222;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #222;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #222;
  --bs-table-hover-bg: rgba(64, 153, 255, 0.02);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.7rem 0.75rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(1px * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.3rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d9ebff;
  --bs-table-border-color: #c3d4e6;
  --bs-table-striped-bg: #cedff2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c3d4e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d5e6fa;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dddee0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d5f7f0;
  --bs-table-border-color: #c0ded8;
  --bs-table-striped-bg: #caebe4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0ded8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1f2eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccf2f6;
  --bs-table-border-color: #b8dadd;
  --bs-table-striped-bg: #c2e6ea;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8dadd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8edf1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff0db;
  --bs-table-border-color: #e6d8c5;
  --bs-table-striped-bg: #f2e4d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d8c5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: antiquewhite;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffdde2;
  --bs-table-border-color: #e6c7cb;
  --bs-table-striped-bg: #f2d2d7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c7cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #fad9dd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #f3f4f5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #343a40;
  --bs-table-border-color: #484e53;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #383e44;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.95rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #c0ddff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.95rem;
  margin: -0.375rem -0.95rem;
  margin-inline-end: 0.95rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #222;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 5px;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 5px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 5px;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 5px;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: #e9ecef;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.95rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 5px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2ed8b6;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #000;
  background-color: rgba(46, 216, 182, 0.9);
  border-radius: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2ed8b6;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ed8b6' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + calc($input-padding-y / 2)) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #2ed8b6;
  box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + calc($input-padding-y / 2)) right calc(0.375em + calc($input-padding-y / 2));
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #2ed8b6;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ed8b6' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #2ed8b6;
  box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #2ed8b6;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #2ed8b6;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2ed8b6;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff5370;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #000;
  background-color: rgba(255, 83, 112, 0.9);
  border-radius: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff5370;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5370' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff5370' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + calc($input-padding-y / 2)) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ff5370;
  box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + calc($input-padding-y / 2)) right calc(0.375em + calc($input-padding-y / 2));
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ff5370;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5370' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff5370' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ff5370;
  box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff5370;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ff5370;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff5370;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.95rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 5px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #4099ff;
  --bs-btn-border-color: #4099ff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #5da8ff;
  --bs-btn-hover-border-color: #53a3ff;
  --bs-btn-focus-shadow-rgb: 54, 130, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #66adff;
  --bs-btn-active-border-color: #53a3ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #4099ff;
  --bs-btn-disabled-border-color: #4099ff;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #2ed8b6;
  --bs-btn-border-color: #2ed8b6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #4ddec1;
  --bs-btn-hover-border-color: #43dcbd;
  --bs-btn-focus-shadow-rgb: 39, 184, 155;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #58e0c5;
  --bs-btn-active-border-color: #43dcbd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #2ed8b6;
  --bs-btn-disabled-border-color: #2ed8b6;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #00bcd4;
  --bs-btn-border-color: #00bcd4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26c6da;
  --bs-btn-hover-border-color: #1ac3d8;
  --bs-btn-focus-shadow-rgb: 0, 160, 180;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33c9dd;
  --bs-btn-active-border-color: #1ac3d8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00bcd4;
  --bs-btn-disabled-border-color: #00bcd4;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffb64d;
  --bs-btn-border-color: #ffb64d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc168;
  --bs-btn-hover-border-color: #ffbd5f;
  --bs-btn-focus-shadow-rgb: 217, 155, 65;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc571;
  --bs-btn-active-border-color: #ffbd5f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffb64d;
  --bs-btn-disabled-border-color: #ffb64d;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff5370;
  --bs-btn-border-color: #ff5370;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff6d85;
  --bs-btn-hover-border-color: #ff647e;
  --bs-btn-focus-shadow-rgb: 217, 71, 95;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff758d;
  --bs-btn-active-border-color: #ff647e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff5370;
  --bs-btn-disabled-border-color: #ff5370;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #52585d;
  --bs-btn-hover-border-color: #484e53;
  --bs-btn-focus-shadow-rgb: 82, 88, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5d6166;
  --bs-btn-active-border-color: #484e53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #343a40;
  --bs-btn-disabled-border-color: #343a40;
}

.btn-outline-primary {
  --bs-btn-color: #4099ff;
  --bs-btn-border-color: #4099ff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #4099ff;
  --bs-btn-hover-border-color: #4099ff;
  --bs-btn-focus-shadow-rgb: 64, 153, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4099ff;
  --bs-btn-active-border-color: #4099ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4099ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4099ff;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #2ed8b6;
  --bs-btn-border-color: #2ed8b6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2ed8b6;
  --bs-btn-hover-border-color: #2ed8b6;
  --bs-btn-focus-shadow-rgb: 46, 216, 182;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #2ed8b6;
  --bs-btn-active-border-color: #2ed8b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2ed8b6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2ed8b6;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00bcd4;
  --bs-btn-border-color: #00bcd4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00bcd4;
  --bs-btn-hover-border-color: #00bcd4;
  --bs-btn-focus-shadow-rgb: 0, 188, 212;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00bcd4;
  --bs-btn-active-border-color: #00bcd4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00bcd4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00bcd4;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffb64d;
  --bs-btn-border-color: #ffb64d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffb64d;
  --bs-btn-hover-border-color: #ffb64d;
  --bs-btn-focus-shadow-rgb: 255, 182, 77;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffb64d;
  --bs-btn-active-border-color: #ffb64d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffb64d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffb64d;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff5370;
  --bs-btn-border-color: #ff5370;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff5370;
  --bs-btn-hover-border-color: #ff5370;
  --bs-btn-focus-shadow-rgb: 255, 83, 112;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff5370;
  --bs-btn-active-border-color: #ff5370;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff5370;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff5370;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #343a40;
  --bs-btn-hover-border-color: #343a40;
  --bs-btn-focus-shadow-rgb: 52, 58, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #343a40;
  --bs-btn-active-border-color: #343a40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #343a40;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 54, 130, 217;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 5px;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 5px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1030;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #222;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-border-radius: 5px;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(5px - 1px);
  --bs-dropdown-divider-bg: #e9ecef;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #16181b;
  --bs-dropdown-link-hover-bg: #f8f9fa;
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #4099ff;
  --bs-dropdown-link-disabled-color: #6c757d;
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 5px;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(1px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.7125rem;
  padding-left: 0.7125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(1px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 5px;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #ffffff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 5px;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #4099ff;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 1rem;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.5);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 5px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.5);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 25px;
  --bs-card-spacer-x: 25px;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 1px;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 5px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(5px - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238cc2ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238cc2ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 1rem;
  --bs-breadcrumb-padding-y: 0.75rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: #f6f7fb;
  --bs-breadcrumb-border-radius: 5px;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.5rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #4099ff;
  --bs-pagination-bg: #ffffff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: #4099ff;
  --bs-pagination-hover-bg: #f6f7fb;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #4099ff;
  --bs-pagination-active-border-color: #4099ff;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #ffffff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.4em;
  --bs-badge-padding-y: 0.25em;
  --bs-badge-font-size: 75%;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 5px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 5px;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #f6f7fb;
  --bs-progress-border-radius: 5px;
  --bs-progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: t #4099ff;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: #ffffff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 5px;
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: 0.75rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #222;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #ffffff;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #4099ff;
  --bs-list-group-active-border-color: #4099ff;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 5px;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.25rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 5px;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-modal-inner-border-radius: 4px;
  --bs-modal-header-padding-x: 1.5625rem;
  --bs-modal-header-padding-y: 1.25rem;
  --bs-modal-header-padding: 1.25rem 1.5625rem;
  --bs-modal-header-border-color: #e3eaef;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #e3eaef;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #ffffff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 5px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: 5px;
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 0.75rem;
  --bs-popover-body-padding-y: 0.5rem;
  --bs-popover-body-color: #222;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(64, 153, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(46, 216, 182, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 188, 212, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 182, 77, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(255, 83, 112, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(52, 58, 64, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(102, 173, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(102, 173, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(88, 224, 197, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(88, 224, 197, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(51, 201, 221, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 201, 221, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 197, 113, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 197, 113, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(255, 117, 141, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 117, 141, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(42, 46, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 46, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.5 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.5 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/**  =====================
      Custom css start
==========================  **/
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #222;
  font-weight: 400;
  background: #f6f7fb;
  position: relative;
}

*:focus {
  outline: none;
}

a:hover {
  outline: none;
  text-decoration: none;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #222;
  font-weight: 600;
}

h1, .h1 {
  font-size: 40px;
}

h2, .h2 {
  font-size: 32px;
}

h3, .h3 {
  font-size: 28px;
}

h4, .h4 {
  font-size: 22px;
}

h5, .h5 {
  font-size: 20px;
}

h6, .h6 {
  font-size: 14px;
}

p {
  font-size: 14px;
}

b,
strong {
  font-weight: 600;
}

.img-radius {
  border-radius: 50%;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.page-header {
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  background: #fff;
  padding: 20px 25px;
  border-radius: 5px;
}
.page-header h5, .page-header .h5 {
  margin-bottom: 16px;
  padding-bottom: 12px;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 600;
}
.page-header .page-header-title + .breadcrumb {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}
.page-header .page-header-title + .breadcrumb > .breadcrumb-item {
  line-height: 1;
}
.page-header .page-header-title + .breadcrumb > .breadcrumb-item a {
  color: #4099ff;
  font-weight: 400;
}
.page-header .page-header-title + .breadcrumb > .breadcrumb-item:last-child a {
  color: #222;
  font-weight: 400;
}

.pcoded-main-container .page-header {
  margin-bottom: 30px;
}

/* ==========  card css start  =========== */
.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.anim-close-card {
  animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.dropdown-menu {
  padding: 10px 0;
  font-size: 14px;
}
.dropdown-menu > li,
.dropdown-menu > .dropdown-item {
  padding-left: 8px;
  padding-right: 8px;
  margin: 0 10px;
  width: auto;
  border-radius: 2px;
}
.dropdown-menu > li > a,
.dropdown-menu > .dropdown-item > a {
  padding: 5px;
  color: #222;
}
.dropdown-menu > li > a i,
.dropdown-menu > .dropdown-item > a i {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.dropdown-menu > li.active, .dropdown-menu > li:active, .dropdown-menu > li:focus, .dropdown-menu > li:hover,
.dropdown-menu > .dropdown-item.active,
.dropdown-menu > .dropdown-item:active,
.dropdown-menu > .dropdown-item:focus,
.dropdown-menu > .dropdown-item:hover {
  background: #4099ff;
  color: #fff;
  box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);
}
.dropdown-menu > li.active > a, .dropdown-menu > li:active > a, .dropdown-menu > li:focus > a, .dropdown-menu > li:hover > a,
.dropdown-menu > .dropdown-item.active > a,
.dropdown-menu > .dropdown-item:active > a,
.dropdown-menu > .dropdown-item:focus > a,
.dropdown-menu > .dropdown-item:hover > a {
  background: transparent;
  color: #fff;
}
.dropdown-menu > a.active, .dropdown-menu > a:active, .dropdown-menu > a:focus, .dropdown-menu > a:hover {
  background: #4099ff;
  color: #fff;
}

.drp-icon:after {
  display: none;
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
a {
  text-decoration: none !important;
}

/* ================================    tooltip slider Start  ===================== */
.tooltip.in {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  border-color: transparent;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

/* ================================    tooltip slider End  ===================== */
/* ================================    Blockquote Start  ===================== */
.blockquote {
  border-left: 0.25rem solid #e2e5e8;
  padding: 0.5rem 1rem;
}
.blockquote.text-end {
  border-left: none;
  border-right: 0.25rem solid #e2e5e8;
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */
.card .animated {
  animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}
.m-t-0 {
  margin-top: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}

.mt-n5 {
  margin-top: -3rem !important;
}

/*====== Padding , Margin css ends ======*/
/*====== text-color, background & border css starts ======*/
.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.text-c-blue {
  color: #4099ff;
}

.b-c-blue {
  border: 1px solid #4099ff;
}

.bg-c-red {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.text-c-red {
  color: #ff5370;
}

.b-c-red {
  border: 1px solid #ff5370;
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.text-c-green {
  color: #2ed8b6;
}

.b-c-green {
  border: 1px solid #2ed8b6;
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.text-c-yellow {
  color: #ffb64d;
}

.b-c-yellow {
  border: 1px solid #ffb64d;
}

.bg-c-purple {
  background: linear-gradient(45deg, #7759de, #9a84e6);
}

.text-c-purple {
  color: #7759de;
}

.b-c-purple {
  border: 1px solid #7759de;
}

/*====== text-color, background & border css ends ======*/
/*====== [ overrides bootstrap color ]text-color, background & border css starts ======*/
.bg-primary {
  background: linear-gradient(45deg, #4099ff, #73b4ff) !important;
}

.text-primary {
  color: #4099ff !important;
}

.b-primary {
  border: 1px solid #4099ff !important;
}

.text-h-primary:hover {
  color: #4099ff !important;
}

.bg-danger {
  background: linear-gradient(45deg, #ff5370, #ff869a) !important;
}

.text-danger {
  color: #ff5370 !important;
}

.b-danger {
  border: 1px solid #ff5370 !important;
}

.text-h-danger:hover {
  color: #ff5370 !important;
}

.bg-success {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5) !important;
}

.text-success {
  color: #2ed8b6 !important;
}

.b-success {
  border: 1px solid #2ed8b6 !important;
}

.text-h-success:hover {
  color: #2ed8b6 !important;
}

.bg-warning {
  background: linear-gradient(45deg, #ffb64d, #ffcb80) !important;
}

.text-warning {
  color: #ffb64d !important;
}

.b-warning {
  border: 1px solid #ffb64d !important;
}

.text-h-warning:hover {
  color: #ffb64d !important;
}

.bg-info {
  background: linear-gradient(45deg, #00bcd4, #08e3ff) !important;
}

.text-info {
  color: #00bcd4 !important;
}

.b-info {
  border: 1px solid #00bcd4 !important;
}

.text-h-info:hover {
  color: #00bcd4 !important;
}

.bg-purple {
  background: linear-gradient(45deg, #7759de, #9a84e6) !important;
}

.text-purple {
  color: #7759de !important;
}

.b-purple {
  border: 1px solid #7759de !important;
}

.text-h-purple:hover {
  color: #7759de !important;
}

/*====== [ overrides bootstrap color ]text-color, background & border css ends ======*/
/*====== border color css starts ======*/
.b-primary {
  border: 1px solid #4099ff;
}

.b-danger {
  border: 1px solid #ff5370;
}

.b-success {
  border: 1px solid #2ed8b6;
}

.b-warning {
  border: 1px solid #ffb64d;
}

.b-info {
  border: 1px solid #00bcd4;
}

.b-purple {
  border: 1px solid #7759de;
}

/*====== border color css ends ======*/
/*====== Card top border css starts ======*/
.card-border-c-blue {
  border-top: 4px solid #4099ff;
}

.card-border-c-red {
  border-top: 4px solid #ff5370;
}

.card-border-c-green {
  border-top: 4px solid #2ed8b6;
}

.card-border-c-yellow {
  border-top: 4px solid #ffb64d;
}

.card-border-c-purple {
  border-top: 4px solid #7759de;
}

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
.wid-20 {
  width: 20px;
}

.wid-25 {
  width: 25px;
}

.wid-30 {
  width: 30px;
}

.wid-35 {
  width: 35px;
}

.wid-40 {
  width: 40px;
}

.wid-45 {
  width: 45px;
}

.wid-50 {
  width: 50px;
}

.wid-55 {
  width: 55px;
}

.wid-60 {
  width: 60px;
}

.wid-65 {
  width: 65px;
}

.wid-70 {
  width: 70px;
}

.wid-75 {
  width: 75px;
}

.wid-80 {
  width: 80px;
}

.wid-85 {
  width: 85px;
}

.wid-90 {
  width: 90px;
}

.wid-95 {
  width: 95px;
}

.wid-100 {
  width: 100px;
}

.wid-105 {
  width: 105px;
}

.wid-110 {
  width: 110px;
}

.wid-115 {
  width: 115px;
}

.wid-120 {
  width: 120px;
}

.wid-125 {
  width: 125px;
}

.wid-130 {
  width: 130px;
}

.wid-135 {
  width: 135px;
}

.wid-140 {
  width: 140px;
}

.wid-145 {
  width: 145px;
}

.wid-150 {
  width: 150px;
}

/*====== width, Height css ends ======*/
@font-face {
  font-family: "pct";
  src: url("./../../../assets/fonts/pct.eot");
  src: url("./../../../assets/fonts/pct.eot?#iefix") format("embedded-opentype"), url("./../../../assets/fonts/pct.woff") format("woff"), url("./../../../assets/fonts/pct.ttf") format("truetype"), url("./../../../assets/fonts/pct.svg#pct") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "pct" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=pct-]:before,
[class*=" pct-"]:before {
  font-family: "pct" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pct-arrow1:before {
  content: "a";
}

.pct-arrow2:before {
  content: "b";
}

.pct-arrow3:before {
  content: "c";
}

.pct-arrow4:before {
  content: "d";
}

.pct-chat1:before {
  content: "e";
}

.pct-chat2:before {
  content: "f";
}

.pct-chat3:before {
  content: "g";
}

.pct-chat4:before {
  content: "h";
}

.pct-loader1:before {
  content: "i";
}

.pct-arrow-sharp1:before {
  content: "j";
}

.pct-arrow-sharp2:before {
  content: "k";
}

.pct-arrow-sharp3:before {
  content: "l";
}

.pct-arrow-sharp4:before {
  content: "m";
}

@font-face {
  font-family: "feather";
  src: url("./../../../assets/fonts/feather.eot?t=1501841394106");
  /* IE9*/
  src: url("./../../../assets/fonts/feather.eot?t=1501841394106#iefix") format("embedded-opentype"), url("./../../../assets/fonts/feather.woff?t=1501841394106") format("woff"), url("./../../../assets/fonts/feather.ttf?t=1501841394106") format("truetype"), url("./../../../assets/fonts/feather.svg?t=1501841394106#feather") format("svg");
  /* iOS 4.1- */
}
.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-octagon:before {
  content: "\e81b";
}

.icon-alert-circle:before {
  content: "\e81c";
}

.icon-activity:before {
  content: "\e81d";
}

.icon-alert-triangle:before {
  content: "\e81e";
}

.icon-align-center:before {
  content: "\e81f";
}

.icon-airplay:before {
  content: "\e820";
}

.icon-align-justify:before {
  content: "\e821";
}

.icon-align-left:before {
  content: "\e822";
}

.icon-align-right:before {
  content: "\e823";
}

.icon-arrow-down-left:before {
  content: "\e824";
}

.icon-arrow-down-right:before {
  content: "\e825";
}

.icon-anchor:before {
  content: "\e826";
}

.icon-aperture:before {
  content: "\e827";
}

.icon-arrow-left:before {
  content: "\e828";
}

.icon-arrow-right:before {
  content: "\e829";
}

.icon-arrow-down:before {
  content: "\e82a";
}

.icon-arrow-up-left:before {
  content: "\e82b";
}

.icon-arrow-up-right:before {
  content: "\e82c";
}

.icon-arrow-up:before {
  content: "\e82d";
}

.icon-award:before {
  content: "\e82e";
}

.icon-bar-chart:before {
  content: "\e82f";
}

.icon-at-sign:before {
  content: "\e830";
}

.icon-bar-chart-2:before {
  content: "\e831";
}

.icon-battery-charging:before {
  content: "\e832";
}

.icon-bell-off:before {
  content: "\e833";
}

.icon-battery:before {
  content: "\e834";
}

.icon-bluetooth:before {
  content: "\e835";
}

.icon-bell:before {
  content: "\e836";
}

.icon-book:before {
  content: "\e837";
}

.icon-briefcase:before {
  content: "\e838";
}

.icon-camera-off:before {
  content: "\e839";
}

.icon-calendar:before {
  content: "\e83a";
}

.icon-bookmark:before {
  content: "\e83b";
}

.icon-box:before {
  content: "\e83c";
}

.icon-camera:before {
  content: "\e83d";
}

.icon-check-circle:before {
  content: "\e83e";
}

.icon-check:before {
  content: "\e83f";
}

.icon-check-square:before {
  content: "\e840";
}

.icon-cast:before {
  content: "\e841";
}

.icon-chevron-down:before {
  content: "\e842";
}

.icon-chevron-left:before {
  content: "\e843";
}

.icon-chevron-right:before {
  content: "\e844";
}

.icon-chevron-up:before {
  content: "\e845";
}

.icon-chevrons-down:before {
  content: "\e846";
}

.icon-chevrons-right:before {
  content: "\e847";
}

.icon-chevrons-up:before {
  content: "\e848";
}

.icon-chevrons-left:before {
  content: "\e849";
}

.icon-circle:before {
  content: "\e84a";
}

.icon-clipboard:before {
  content: "\e84b";
}

.icon-chrome:before {
  content: "\e84c";
}

.icon-clock:before {
  content: "\e84d";
}

.icon-cloud-lightning:before {
  content: "\e84e";
}

.icon-cloud-drizzle:before {
  content: "\e84f";
}

.icon-cloud-rain:before {
  content: "\e850";
}

.icon-cloud-off:before {
  content: "\e851";
}

.icon-codepen:before {
  content: "\e852";
}

.icon-cloud-snow:before {
  content: "\e853";
}

.icon-compass:before {
  content: "\e854";
}

.icon-copy:before {
  content: "\e855";
}

.icon-corner-down-right:before {
  content: "\e856";
}

.icon-corner-down-left:before {
  content: "\e857";
}

.icon-corner-left-down:before {
  content: "\e858";
}

.icon-corner-left-up:before {
  content: "\e859";
}

.icon-corner-up-left:before {
  content: "\e85a";
}

.icon-corner-up-right:before {
  content: "\e85b";
}

.icon-corner-right-down:before {
  content: "\e85c";
}

.icon-corner-right-up:before {
  content: "\e85d";
}

.icon-cpu:before {
  content: "\e85e";
}

.icon-credit-card:before {
  content: "\e85f";
}

.icon-crosshair:before {
  content: "\e860";
}

.icon-disc:before {
  content: "\e861";
}

.icon-delete:before {
  content: "\e862";
}

.icon-download-cloud:before {
  content: "\e863";
}

.icon-download:before {
  content: "\e864";
}

.icon-droplet:before {
  content: "\e865";
}

.icon-edit-2:before {
  content: "\e866";
}

.icon-edit:before {
  content: "\e867";
}

.icon-edit-1:before {
  content: "\e868";
}

.icon-external-link:before {
  content: "\e869";
}

.icon-eye:before {
  content: "\e86a";
}

.icon-feather:before {
  content: "\e86b";
}

.icon-facebook:before {
  content: "\e86c";
}

.icon-file-minus:before {
  content: "\e86d";
}

.icon-eye-off:before {
  content: "\e86e";
}

.icon-fast-forward:before {
  content: "\e86f";
}

.icon-file-text:before {
  content: "\e870";
}

.icon-film:before {
  content: "\e871";
}

.icon-file:before {
  content: "\e872";
}

.icon-file-plus:before {
  content: "\e873";
}

.icon-folder:before {
  content: "\e874";
}

.icon-filter:before {
  content: "\e875";
}

.icon-flag:before {
  content: "\e876";
}

.icon-globe:before {
  content: "\e877";
}

.icon-grid:before {
  content: "\e878";
}

.icon-heart:before {
  content: "\e879";
}

.icon-home:before {
  content: "\e87a";
}

.icon-github:before {
  content: "\e87b";
}

.icon-image:before {
  content: "\e87c";
}

.icon-inbox:before {
  content: "\e87d";
}

.icon-layers:before {
  content: "\e87e";
}

.icon-info:before {
  content: "\e87f";
}

.icon-instagram:before {
  content: "\e880";
}

.icon-layout:before {
  content: "\e881";
}

.icon-link-2:before {
  content: "\e882";
}

.icon-life-buoy:before {
  content: "\e883";
}

.icon-link:before {
  content: "\e884";
}

.icon-log-in:before {
  content: "\e885";
}

.icon-list:before {
  content: "\e886";
}

.icon-lock:before {
  content: "\e887";
}

.icon-log-out:before {
  content: "\e888";
}

.icon-loader:before {
  content: "\e889";
}

.icon-mail:before {
  content: "\e88a";
}

.icon-maximize-2:before {
  content: "\e88b";
}

.icon-map:before {
  content: "\e88c";
}

.icon-map-pin:before {
  content: "\e88e";
}

.icon-menu:before {
  content: "\e88f";
}

.icon-message-circle:before {
  content: "\e890";
}

.icon-message-square:before {
  content: "\e891";
}

.icon-minimize-2:before {
  content: "\e892";
}

.icon-mic-off:before {
  content: "\e893";
}

.icon-minus-circle:before {
  content: "\e894";
}

.icon-mic:before {
  content: "\e895";
}

.icon-minus-square:before {
  content: "\e896";
}

.icon-minus:before {
  content: "\e897";
}

.icon-moon:before {
  content: "\e898";
}

.icon-monitor:before {
  content: "\e899";
}

.icon-more-vertical:before {
  content: "\e89a";
}

.icon-more-horizontal:before {
  content: "\e89b";
}

.icon-move:before {
  content: "\e89c";
}

.icon-music:before {
  content: "\e89d";
}

.icon-navigation-2:before {
  content: "\e89e";
}

.icon-navigation:before {
  content: "\e89f";
}

.icon-octagon:before {
  content: "\e8a0";
}

.icon-package:before {
  content: "\e8a1";
}

.icon-pause-circle:before {
  content: "\e8a2";
}

.icon-pause:before {
  content: "\e8a3";
}

.icon-percent:before {
  content: "\e8a4";
}

.icon-phone-call:before {
  content: "\e8a5";
}

.icon-phone-forwarded:before {
  content: "\e8a6";
}

.icon-phone-missed:before {
  content: "\e8a7";
}

.icon-phone-off:before {
  content: "\e8a8";
}

.icon-phone-incoming:before {
  content: "\e8a9";
}

.icon-phone:before {
  content: "\e8aa";
}

.icon-phone-outgoing:before {
  content: "\e8ab";
}

.icon-pie-chart:before {
  content: "\e8ac";
}

.icon-play-circle:before {
  content: "\e8ad";
}

.icon-play:before {
  content: "\e8ae";
}

.icon-plus-square:before {
  content: "\e8af";
}

.icon-plus-circle:before {
  content: "\e8b0";
}

.icon-plus:before {
  content: "\e8b1";
}

.icon-pocket:before {
  content: "\e8b2";
}

.icon-printer:before {
  content: "\e8b3";
}

.icon-power:before {
  content: "\e8b4";
}

.icon-radio:before {
  content: "\e8b5";
}

.icon-repeat:before {
  content: "\e8b6";
}

.icon-refresh-ccw:before {
  content: "\e8b7";
}

.icon-rewind:before {
  content: "\e8b8";
}

.icon-rotate-ccw:before {
  content: "\e8b9";
}

.icon-refresh-cw:before {
  content: "\e8ba";
}

.icon-rotate-cw:before {
  content: "\e8bb";
}

.icon-save:before {
  content: "\e8bc";
}

.icon-search:before {
  content: "\e8bd";
}

.icon-server:before {
  content: "\e8be";
}

.icon-scissors:before {
  content: "\e8bf";
}

.icon-share-2:before {
  content: "\e8c0";
}

.icon-share:before {
  content: "\e8c1";
}

.icon-shield:before {
  content: "\e8c2";
}

.icon-settings:before {
  content: "\e8c3";
}

.icon-skip-back:before {
  content: "\e8c4";
}

.icon-shuffle:before {
  content: "\e8c5";
}

.icon-sidebar:before {
  content: "\e8c6";
}

.icon-skip-forward:before {
  content: "\e8c7";
}

.icon-slack:before {
  content: "\e8c8";
}

.icon-slash:before {
  content: "\e8c9";
}

.icon-smartphone:before {
  content: "\e8ca";
}

.icon-square:before {
  content: "\e8cb";
}

.icon-speaker:before {
  content: "\e8cc";
}

.icon-star:before {
  content: "\e8cd";
}

.icon-stop-circle:before {
  content: "\e8ce";
}

.icon-sun:before {
  content: "\e8cf";
}

.icon-sunrise:before {
  content: "\e8d0";
}

.icon-tablet:before {
  content: "\e8d1";
}

.icon-tag:before {
  content: "\e8d2";
}

.icon-sunset:before {
  content: "\e8d3";
}

.icon-target:before {
  content: "\e8d4";
}

.icon-thermometer:before {
  content: "\e8d5";
}

.icon-thumbs-up:before {
  content: "\e8d6";
}

.icon-thumbs-down:before {
  content: "\e8d7";
}

.icon-toggle-left:before {
  content: "\e8d8";
}

.icon-toggle-right:before {
  content: "\e8d9";
}

.icon-trash-2:before {
  content: "\e8da";
}

.icon-trash:before {
  content: "\e8db";
}

.icon-trending-up:before {
  content: "\e8dc";
}

.icon-trending-down:before {
  content: "\e8dd";
}

.icon-triangle:before {
  content: "\e8de";
}

.icon-type:before {
  content: "\e8df";
}

.icon-twitter:before {
  content: "\e8e0";
}

.icon-upload:before {
  content: "\e8e1";
}

.icon-umbrella:before {
  content: "\e8e2";
}

.icon-upload-cloud:before {
  content: "\e8e3";
}

.icon-unlock:before {
  content: "\e8e4";
}

.icon-user-check:before {
  content: "\e8e5";
}

.icon-user-minus:before {
  content: "\e8e6";
}

.icon-user-plus:before {
  content: "\e8e7";
}

.icon-user-x:before {
  content: "\e8e8";
}

.icon-user:before {
  content: "\e8e9";
}

.icon-users:before {
  content: "\e8ea";
}

.icon-video-off:before {
  content: "\e8eb";
}

.icon-video:before {
  content: "\e8ec";
}

.icon-voicemail:before {
  content: "\e8ed";
}

.icon-volume-x:before {
  content: "\e8ee";
}

.icon-volume-2:before {
  content: "\e8ef";
}

.icon-volume-1:before {
  content: "\e8f0";
}

.icon-volume:before {
  content: "\e8f1";
}

.icon-watch:before {
  content: "\e8f2";
}

.icon-wifi:before {
  content: "\e8f3";
}

.icon-x-square:before {
  content: "\e8f4";
}

.icon-wind:before {
  content: "\e8f5";
}

.icon-x:before {
  content: "\e8f6";
}

.icon-x-circle:before {
  content: "\e8f7";
}

.icon-zap:before {
  content: "\e8f8";
}

.icon-zoom-in:before {
  content: "\e8f9";
}

.icon-zoom-out:before {
  content: "\e8fa";
}

.icon-command:before {
  content: "\e8fb";
}

.icon-cloud:before {
  content: "\e8fc";
}

.icon-hash:before {
  content: "\e8fd";
}

.icon-headphones:before {
  content: "\e8fe";
}

.icon-underline:before {
  content: "\e8ff";
}

.icon-italic:before {
  content: "\e900";
}

.icon-bold:before {
  content: "\e901";
}

.icon-crop:before {
  content: "\e902";
}

.icon-help-circle:before {
  content: "\e903";
}

.icon-paperclip:before {
  content: "\e904";
}

.icon-shopping-cart:before {
  content: "\e905";
}

.icon-tv:before {
  content: "\e906";
}

.icon-wifi-off:before {
  content: "\e907";
}

.icon-minimize:before {
  content: "\e88d";
}

.icon-maximize:before {
  content: "\e908";
}

.icon-gitlab:before {
  content: "\e909";
}

.icon-sliders:before {
  content: "\e90a";
}

.icon-star-on:before {
  content: "\e90b";
}

.icon-heart-on:before {
  content: "\e90c";
}

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: 0.08em solid #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-horizontal.fa-flip-vertical,
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(./../../../assets/fonts/fa-brands-400.eot);
  src: url(./../../../assets/fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(./../../../assets/fonts/fa-brands-400.woff2) format("woff2"), url(./../../../assets/fonts/fa-brands-400.woff) format("woff"), url(./../../../assets/fonts/fa-brands-400.ttf) format("truetype"), url(./../../../assets/fonts/fa-brands-400.svg#fontawesome) format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url(./../../../assets/fonts/fa-regular-400.eot);
  src: url(./../../../assets/fonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(./../../../assets/fonts/fa-regular-400.woff2) format("woff2"), url(./../../../assets/fonts/fa-regular-400.woff) format("woff"), url(./../../../assets/fonts/fa-regular-400.ttf) format("truetype"), url(./../../../assets/fonts/fa-regular-400.svg#fontawesome) format("svg");
}
.far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url(./../../../assets/fonts/fa-solid-900.eot);
  src: url(./../../../assets/fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(./../../../assets/fonts/fa-solid-900.woff2) format("woff2"), url(./../../../assets/fonts/fa-solid-900.woff) format("woff"), url(./../../../assets/fonts/fa-solid-900.ttf) format("truetype"), url(./../../../assets/fonts/fa-solid-900.svg#fontawesome) format("svg");
}
.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}

.fa,
.fas {
  font-weight: 900;
}

/*
    Flaticon icon font: Flaticon
    Creation date: 11/04/2019 05:01
    */
@font-face {
  font-family: "Flaticon";
  src: url("./../../../assets/fonts/Flaticon.eot");
  src: url("./../../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("./../../../assets/fonts/Flaticon.woff2") format("woff2"), url("./../../../assets/fonts/Flaticon.woff") format("woff"), url("./../../../assets/fonts/Flaticon.ttf") format("truetype"), url("./../../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./../../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-shield:before {
  content: "\f100";
}

.flaticon-layout:before {
  content: "\f101";
}

.flaticon-layers:before {
  content: "\f102";
}

.flaticon-cube:before {
  content: "\f103";
}

.flaticon-compass:before {
  content: "\f104";
}

.flaticon-thunderbolt:before {
  content: "\f105";
}

.flaticon-gift:before {
  content: "\f106";
}

.flaticon-shapes-and-symbols:before {
  content: "\f107";
}

.flaticon-feather:before {
  content: "\f108";
}

.flaticon-menu:before {
  content: "\f109";
}

.flaticon-abc:before {
  content: "\f10a";
}

.flaticon-alarm:before {
  content: "\f10b";
}

.flaticon-locker:before {
  content: "\f10c";
}

.flaticon-menu-1:before {
  content: "\f10d";
}

.flaticon-crop:before {
  content: "\f10e";
}

.flaticon-mail:before {
  content: "\f10f";
}

.flaticon-diamond:before {
  content: "\f110";
}

.flaticon-edit:before {
  content: "\f111";
}

.flaticon-gear:before {
  content: "\f112";
}

.flaticon-video:before {
  content: "\f113";
}

.flaticon-clipboard:before {
  content: "\f114";
}

.flaticon-search:before {
  content: "\f115";
}

.flaticon-data-storage:before {
  content: "\f116";
}

.flaticon-table:before {
  content: "\f117";
}

.flaticon-pie-chart:before {
  content: "\f118";
}

.flaticon-map:before {
  content: "\f119";
}

.flaticon-calendar:before {
  content: "\f11a";
}

.flaticon-mixer:before {
  content: "\f11b";
}

.flaticon-chat:before {
  content: "\f11c";
}

.flaticon-lock:before {
  content: "\f11d";
}

.flaticon-photo:before {
  content: "\f11e";
}

.flaticon-start-up:before {
  content: "\f11f";
}

.flaticon-test:before {
  content: "\f120";
}

.flaticon-study:before {
  content: "\f121";
}

.flaticon-information:before {
  content: "\f122";
}

.flaticon-support:before {
  content: "\f123";
}

.flaticon-help:before {
  content: "\f124";
}

.flaticon-business-and-finance:before {
  content: "\f125";
}

.flaticon-cloud:before {
  content: "\f126";
}

.flaticon-grid:before {
  content: "\f127";
}

.flaticon-text:before {
  content: "\f128";
}

.flaticon-draft:before {
  content: "\f129";
}

.flaticon-writing:before {
  content: "\f12a";
}

.flaticon-flag:before {
  content: "\f12b";
}

.flaticon-waiting:before {
  content: "\f12c";
}

.flaticon-questions:before {
  content: "\f12d";
}

/**  =====================
      Custom css start
==========================  **/
/* ==========  card css start  =========== */
.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.anim-close-card {
  animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.card {
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.card .card-header {
  border-bottom: 0px solid #e2e5e8;
  position: relative;
  padding: 25px 25px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card .card-header + .card-body {
  padding-top: 0;
}
.card .card-header h5, .card .card-header .h5 {
  margin-bottom: 0;
  color: #222;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}
.card .card-header h5 + p, .card .card-header .h5 + p,
.card .card-header h5 + small,
.card .card-header .h5 + small,
.card .card-header h5 + .small,
.card .card-header .h5 + .small {
  margin-top: 10px;
}
.card .card-header h5 + p:last-child, .card .card-header .h5 + p:last-child,
.card .card-header h5 + small:last-child,
.card .card-header .h5 + small:last-child,
.card .card-header h5 + .small:last-child,
.card .card-header .h5 + .small:last-child {
  margin-bottom: 0;
}
.card .card-header .app-card-header h5, .card .card-header .app-card-header .h5 {
  display: inline-block;
}
.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  float: right;
  padding: 0;
  position: absolute;
}
@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}
.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}
.card .card-header .card-header-right .dropdown-menu li {
  cursor: pointer;
}
.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}
.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}
.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 8px;
}
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span {
  background-color: #888;
  height: 2px;
  border-radius: 5px;
}
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:after, .card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:before {
  border-radius: 5px;
  height: 2px;
  background-color: #888;
}
.card .card-header .card-header-right .nav-pills {
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.card .card-footer {
  border-top: 1px solid #e2e5e8;
  padding: 12px 25px;
}
.card .card-footer:not([class*=bg-]) {
  background: transparent;
}
.card .card-footer[class*=bg-] {
  border-top: none;
}
.card .card-block,
.card .card-body {
  padding: 20px 25px;
}
.card.card-load {
  position: relative;
  overflow: hidden;
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: #4099ff;
  font-size: 24px;
  align-items: center;
  display: flex;
}
.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

/**  =====================
      button css start
==========================  **/
/* Button variants
 Easily pump out default styles, as well as :hover, :focus, :active,
 and disabled options for all buttons */
.btn-page .btn {
  margin-right: 5px;
  margin-bottom: 5px;
}
.btn-page .btn-group-vertical .btn,
.btn-page .btn-group .btn {
  margin-right: 0;
  margin-bottom: 0;
}

.btn-primary {
  color: #fff;
  background-color: #4099ff;
  border-color: #4099ff;
}
.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #1a85ff;
  border-color: #0d7eff;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #4099ff;
  border-color: #4099ff;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0d7eff;
  border-color: #0077ff;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-success {
  color: #fff;
  background-color: #2ed8b6;
  border-color: #2ed8b6;
}
.btn-success:active, .btn-success:focus, .btn-success:hover {
  color: #fff;
  background-color: #23bd9e;
  border-color: #21b295;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #2ed8b6;
  border-color: #2ed8b6;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #21b295;
  border-color: #1fa78c;
}

.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-info:active, .btn-info:focus, .btn-info:hover {
  color: #fff;
  background-color: #009aae;
  border-color: #008fa1;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #008fa1;
  border-color: #008394;
}

.btn-warning {
  color: #fff;
  background-color: #ffb64d;
  border-color: #ffb64d;
}
.btn-warning:active, .btn-warning:focus, .btn-warning:hover {
  color: #fff;
  background-color: #ffa627;
  border-color: #ffa11a;
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #ffb64d;
  border-color: #ffb64d;
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffa11a;
  border-color: #ff9c0d;
}

.btn-danger {
  color: #fff;
  background-color: #ff5370;
  border-color: #ff5370;
}
.btn-danger:active, .btn-danger:focus, .btn-danger:hover {
  color: #fff;
  background-color: #ff2d50;
  border-color: #ff2046;
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ff5370;
  border-color: #ff5370;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff2046;
  border-color: #ff133b;
}

.btn-light {
  color: #37474f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:active, .btn-light:focus, .btn-light:hover {
  color: #37474f;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.disabled, .btn-light:disabled {
  color: #37474f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #37474f;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:active, .btn-dark:focus, .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-light-primary {
  background: #d9ebff;
  color: #4099ff;
  border-color: #d9ebff;
}
.btn-light-primary .material-icons-two-tone {
  background-color: #4099ff;
}
.btn-light-primary:hover {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
.btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-primary.focus, .btn-light-primary:focus {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
.btn-light-primary.focus .material-icons-two-tone, .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-primary:not(:disabled):not(.disabled).active, .btn-light-primary:not(:disabled):not(.disabled):active, .show > .btn-light-primary.dropdown-toggle {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
.btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-primary,
.btn-check:checked + .btn-light-primary {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
.btn-check:active + .btn-light-primary .material-icons-two-tone,
.btn-check:checked + .btn-light-primary .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-primary {
  background: transparent;
  color: #4099ff;
  border-color: transparent;
}
.btn-link-primary .material-icons-two-tone {
  background-color: #4099ff;
}
.btn-link-primary:hover {
  background: #d9ebff;
  color: #4099ff;
  border-color: #d9ebff;
}
.btn-link-primary.focus, .btn-link-primary:focus {
  background: #d9ebff;
  color: #4099ff;
  border-color: #d9ebff;
}
.btn-link-primary:not(:disabled):not(.disabled).active, .btn-link-primary:not(:disabled):not(.disabled):active, .show > .btn-link-primary.dropdown-toggle {
  background: #d9ebff;
  color: #4099ff;
  border-color: #d9ebff;
}

.btn-check:active + .btn-link-primary,
.btn-check:checked + .btn-link-primary {
  background: #d9ebff;
  color: #4099ff;
  border-color: #d9ebff;
}

.btn-light-secondary {
  background: #e2e3e5;
  color: #6c757d;
  border-color: #e2e3e5;
}
.btn-light-secondary .material-icons-two-tone {
  background-color: #6c757d;
}
.btn-light-secondary:hover {
  background: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-secondary.focus, .btn-light-secondary:focus {
  background: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.btn-light-secondary.focus .material-icons-two-tone, .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-secondary:not(:disabled):not(.disabled).active, .btn-light-secondary:not(:disabled):not(.disabled):active, .show > .btn-light-secondary.dropdown-toggle {
  background: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-secondary,
.btn-check:checked + .btn-light-secondary {
  background: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.btn-check:active + .btn-light-secondary .material-icons-two-tone,
.btn-check:checked + .btn-light-secondary .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-secondary {
  background: transparent;
  color: #6c757d;
  border-color: transparent;
}
.btn-link-secondary .material-icons-two-tone {
  background-color: #6c757d;
}
.btn-link-secondary:hover {
  background: #e2e3e5;
  color: #6c757d;
  border-color: #e2e3e5;
}
.btn-link-secondary.focus, .btn-link-secondary:focus {
  background: #e2e3e5;
  color: #6c757d;
  border-color: #e2e3e5;
}
.btn-link-secondary:not(:disabled):not(.disabled).active, .btn-link-secondary:not(:disabled):not(.disabled):active, .show > .btn-link-secondary.dropdown-toggle {
  background: #e2e3e5;
  color: #6c757d;
  border-color: #e2e3e5;
}

.btn-check:active + .btn-link-secondary,
.btn-check:checked + .btn-link-secondary {
  background: #e2e3e5;
  color: #6c757d;
  border-color: #e2e3e5;
}

.btn-light-success {
  background: #d5f7f0;
  color: #2ed8b6;
  border-color: #d5f7f0;
}
.btn-light-success .material-icons-two-tone {
  background-color: #2ed8b6;
}
.btn-light-success:hover {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
.btn-light-success:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-success.focus, .btn-light-success:focus {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
.btn-light-success.focus .material-icons-two-tone, .btn-light-success:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-success:not(:disabled):not(.disabled).active, .btn-light-success:not(:disabled):not(.disabled):active, .show > .btn-light-success.dropdown-toggle {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
.btn-light-success:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-success:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-success.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-success,
.btn-check:checked + .btn-light-success {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
.btn-check:active + .btn-light-success .material-icons-two-tone,
.btn-check:checked + .btn-light-success .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-success {
  background: transparent;
  color: #2ed8b6;
  border-color: transparent;
}
.btn-link-success .material-icons-two-tone {
  background-color: #2ed8b6;
}
.btn-link-success:hover {
  background: #d5f7f0;
  color: #2ed8b6;
  border-color: #d5f7f0;
}
.btn-link-success.focus, .btn-link-success:focus {
  background: #d5f7f0;
  color: #2ed8b6;
  border-color: #d5f7f0;
}
.btn-link-success:not(:disabled):not(.disabled).active, .btn-link-success:not(:disabled):not(.disabled):active, .show > .btn-link-success.dropdown-toggle {
  background: #d5f7f0;
  color: #2ed8b6;
  border-color: #d5f7f0;
}

.btn-check:active + .btn-link-success,
.btn-check:checked + .btn-link-success {
  background: #d5f7f0;
  color: #2ed8b6;
  border-color: #d5f7f0;
}

.btn-light-info {
  background: #ccf2f6;
  color: #00bcd4;
  border-color: #ccf2f6;
}
.btn-light-info .material-icons-two-tone {
  background-color: #00bcd4;
}
.btn-light-info:hover {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
.btn-light-info:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-info.focus, .btn-light-info:focus {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
.btn-light-info.focus .material-icons-two-tone, .btn-light-info:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-info:not(:disabled):not(.disabled).active, .btn-light-info:not(:disabled):not(.disabled):active, .show > .btn-light-info.dropdown-toggle {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
.btn-light-info:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-info:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-info.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-info,
.btn-check:checked + .btn-light-info {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
.btn-check:active + .btn-light-info .material-icons-two-tone,
.btn-check:checked + .btn-light-info .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-info {
  background: transparent;
  color: #00bcd4;
  border-color: transparent;
}
.btn-link-info .material-icons-two-tone {
  background-color: #00bcd4;
}
.btn-link-info:hover {
  background: #ccf2f6;
  color: #00bcd4;
  border-color: #ccf2f6;
}
.btn-link-info.focus, .btn-link-info:focus {
  background: #ccf2f6;
  color: #00bcd4;
  border-color: #ccf2f6;
}
.btn-link-info:not(:disabled):not(.disabled).active, .btn-link-info:not(:disabled):not(.disabled):active, .show > .btn-link-info.dropdown-toggle {
  background: #ccf2f6;
  color: #00bcd4;
  border-color: #ccf2f6;
}

.btn-check:active + .btn-link-info,
.btn-check:checked + .btn-link-info {
  background: #ccf2f6;
  color: #00bcd4;
  border-color: #ccf2f6;
}

.btn-light-warning {
  background: #fff0db;
  color: #ffb64d;
  border-color: #fff0db;
}
.btn-light-warning .material-icons-two-tone {
  background-color: #ffb64d;
}
.btn-light-warning:hover {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
.btn-light-warning:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-warning.focus, .btn-light-warning:focus {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
.btn-light-warning.focus .material-icons-two-tone, .btn-light-warning:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-warning:not(:disabled):not(.disabled).active, .btn-light-warning:not(:disabled):not(.disabled):active, .show > .btn-light-warning.dropdown-toggle {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
.btn-light-warning:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-warning:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-warning.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-warning,
.btn-check:checked + .btn-light-warning {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
.btn-check:active + .btn-light-warning .material-icons-two-tone,
.btn-check:checked + .btn-light-warning .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-warning {
  background: transparent;
  color: #ffb64d;
  border-color: transparent;
}
.btn-link-warning .material-icons-two-tone {
  background-color: #ffb64d;
}
.btn-link-warning:hover {
  background: #fff0db;
  color: #ffb64d;
  border-color: #fff0db;
}
.btn-link-warning.focus, .btn-link-warning:focus {
  background: #fff0db;
  color: #ffb64d;
  border-color: #fff0db;
}
.btn-link-warning:not(:disabled):not(.disabled).active, .btn-link-warning:not(:disabled):not(.disabled):active, .show > .btn-link-warning.dropdown-toggle {
  background: #fff0db;
  color: #ffb64d;
  border-color: #fff0db;
}

.btn-check:active + .btn-link-warning,
.btn-check:checked + .btn-link-warning {
  background: #fff0db;
  color: #ffb64d;
  border-color: #fff0db;
}

.btn-light-danger {
  background: #ffdde2;
  color: #ff5370;
  border-color: #ffdde2;
}
.btn-light-danger .material-icons-two-tone {
  background-color: #ff5370;
}
.btn-light-danger:hover {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
.btn-light-danger:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-danger.focus, .btn-light-danger:focus {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
.btn-light-danger.focus .material-icons-two-tone, .btn-light-danger:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-danger:not(:disabled):not(.disabled).active, .btn-light-danger:not(:disabled):not(.disabled):active, .show > .btn-light-danger.dropdown-toggle {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
.btn-light-danger:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-danger:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-danger.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-danger,
.btn-check:checked + .btn-light-danger {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
.btn-check:active + .btn-light-danger .material-icons-two-tone,
.btn-check:checked + .btn-light-danger .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-danger {
  background: transparent;
  color: #ff5370;
  border-color: transparent;
}
.btn-link-danger .material-icons-two-tone {
  background-color: #ff5370;
}
.btn-link-danger:hover {
  background: #ffdde2;
  color: #ff5370;
  border-color: #ffdde2;
}
.btn-link-danger.focus, .btn-link-danger:focus {
  background: #ffdde2;
  color: #ff5370;
  border-color: #ffdde2;
}
.btn-link-danger:not(:disabled):not(.disabled).active, .btn-link-danger:not(:disabled):not(.disabled):active, .show > .btn-link-danger.dropdown-toggle {
  background: #ffdde2;
  color: #ff5370;
  border-color: #ffdde2;
}

.btn-check:active + .btn-link-danger,
.btn-check:checked + .btn-link-danger {
  background: #ffdde2;
  color: #ff5370;
  border-color: #ffdde2;
}

.btn-light-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.btn-light-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
.btn-light-light:hover {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-light.focus, .btn-light-light:focus {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light.focus .material-icons-two-tone, .btn-light-light:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-light:not(:disabled):not(.disabled).active, .btn-light-light:not(:disabled):not(.disabled):active, .show > .btn-light-light.dropdown-toggle {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-light:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-light.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-light,
.btn-check:checked + .btn-light-light {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-check:active + .btn-light-light .material-icons-two-tone,
.btn-check:checked + .btn-light-light .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-light {
  background: transparent;
  color: #f8f9fa;
  border-color: transparent;
}
.btn-link-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
.btn-link-light:hover {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.btn-link-light.focus, .btn-link-light:focus {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.btn-link-light:not(:disabled):not(.disabled).active, .btn-link-light:not(:disabled):not(.disabled):active, .show > .btn-link-light.dropdown-toggle {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}

.btn-check:active + .btn-link-light,
.btn-check:checked + .btn-link-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}

.btn-light-dark {
  background: #d6d8d9;
  color: #343a40;
  border-color: #d6d8d9;
}
.btn-light-dark .material-icons-two-tone {
  background-color: #343a40;
}
.btn-light-dark:hover {
  background: #343a40;
  color: #fff;
  border-color: #343a40;
}
.btn-light-dark:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dark.focus, .btn-light-dark:focus {
  background: #343a40;
  color: #fff;
  border-color: #343a40;
}
.btn-light-dark.focus .material-icons-two-tone, .btn-light-dark:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dark:not(:disabled):not(.disabled).active, .btn-light-dark:not(:disabled):not(.disabled):active, .show > .btn-light-dark.dropdown-toggle {
  background: #343a40;
  color: #fff;
  border-color: #343a40;
}
.btn-light-dark:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dark:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dark.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-dark,
.btn-check:checked + .btn-light-dark {
  background: #343a40;
  color: #fff;
  border-color: #343a40;
}
.btn-check:active + .btn-light-dark .material-icons-two-tone,
.btn-check:checked + .btn-light-dark .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-dark {
  background: transparent;
  color: #343a40;
  border-color: transparent;
}
.btn-link-dark .material-icons-two-tone {
  background-color: #343a40;
}
.btn-link-dark:hover {
  background: #d6d8d9;
  color: #343a40;
  border-color: #d6d8d9;
}
.btn-link-dark.focus, .btn-link-dark:focus {
  background: #d6d8d9;
  color: #343a40;
  border-color: #d6d8d9;
}
.btn-link-dark:not(:disabled):not(.disabled).active, .btn-link-dark:not(:disabled):not(.disabled):active, .show > .btn-link-dark.dropdown-toggle {
  background: #d6d8d9;
  color: #343a40;
  border-color: #d6d8d9;
}

.btn-check:active + .btn-link-dark,
.btn-check:checked + .btn-link-dark {
  background: #d6d8d9;
  color: #343a40;
  border-color: #d6d8d9;
}

/**  =====================
      Radio & Checked css start
==========================  **/
.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.checkbox input[type=checkbox] {
  margin: 0;
  display: none;
  width: 22px;
}
.checkbox input[type=checkbox] + .cr {
  padding-left: 0;
}
.checkbox input[type=checkbox] + .cr:before {
  content: "\e83f";
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 3px;
  font-size: 15px;
  font-family: "feather";
  font-weight: 400;
  line-height: 19px;
  vertical-align: bottom;
  text-align: center;
  background: #ffffff;
  color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.checkbox input[type=checkbox]:checked + .cr:before {
  background: #1dd5d2;
  border-color: #1dd5d2;
  color: #ffffff;
}
.checkbox input[type=checkbox].disabled + .cr, .checkbox input[type=checkbox]:disabled + .cr {
  opacity: 0.5;
}
.checkbox input[type=checkbox].disabled + .cr:before, .checkbox input[type=checkbox]:disabled + .cr:before {
  cursor: not-allowed;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:after {
  content: "";
  width: 22.5px;
  height: 22.5px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 2px;
  vertical-align: bottom;
  text-align: center;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 8.5px;
  left: 3px;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:before {
  opacity: 0;
  content: "\e840";
  font-size: 27px;
  background: transparent;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:after {
  opacity: 0;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:before {
  opacity: 1;
  background: transparent;
  color: #1dd5d2;
  border-color: transparent;
}
.checkbox.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: #4099ff;
  border-color: #4099ff;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #4099ff;
  border-color: transparent;
}
.checkbox.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: #ff5370;
  border-color: #ff5370;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #ff5370;
  border-color: transparent;
}
.checkbox.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: #2ed8b6;
  border-color: #2ed8b6;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #2ed8b6;
  border-color: transparent;
}
.checkbox.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: #ffb64d;
  border-color: #ffb64d;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #ffb64d;
  border-color: transparent;
}
.checkbox.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: #00bcd4;
  border-color: #00bcd4;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #00bcd4;
  border-color: transparent;
}
.checkbox.checkbox-purple input[type=checkbox]:checked + .cr:before {
  background: #7759de;
  border-color: #7759de;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-purple input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #7759de;
  border-color: transparent;
}
.checkbox .cr {
  cursor: pointer;
}

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.radio input[type=radio] {
  margin: 0;
  display: none;
  width: 22px;
}
.radio input[type=radio] + .cr {
  padding-left: 0;
}
.radio input[type=radio] + .cr:after, .radio input[type=radio] + .cr:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: bottom;
  background: #fff;
  color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.radio input[type=radio] + .cr:before {
  width: 22px;
  height: 22px;
  border: 2px solid #e9eaec;
}
.radio input[type=radio] + .cr:after {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 12px;
  left: 4px;
}
.radio input[type=radio]:checked + .cr:before {
  border-color: #4099ff;
}
.radio input[type=radio]:checked + .cr:after {
  background: #4099ff;
}
.radio input[type=radio]:disabled + .cr {
  opacity: 0.5;
  cursor: not-allowed;
}
.radio input[type=radio]:disabled + .cr:after, .radio input[type=radio]:disabled + .cr:before {
  cursor: not-allowed;
}
.radio.radio-fill input[type=radio] + .cr:after, .radio.radio-infill input[type=radio] + .cr:after {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  left: 4px;
}
.radio.radio-s-fill-1 input[type=radio] + .cr:after {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  left: 4px;
}
.radio.radio-primary input[type=radio]:checked + .cr:before {
  border-color: #4099ff;
}
.radio.radio-primary input[type=radio]:checked + .cr:after {
  background: #4099ff;
}
.radio.radio-danger input[type=radio]:checked + .cr:before {
  border-color: #ff5370;
}
.radio.radio-danger input[type=radio]:checked + .cr:after {
  background: #ff5370;
}
.radio.radio-success input[type=radio]:checked + .cr:before {
  border-color: #2ed8b6;
}
.radio.radio-success input[type=radio]:checked + .cr:after {
  background: #2ed8b6;
}
.radio.radio-warning input[type=radio]:checked + .cr:before {
  border-color: #ffb64d;
}
.radio.radio-warning input[type=radio]:checked + .cr:after {
  background: #ffb64d;
}
.radio.radio-info input[type=radio]:checked + .cr:before {
  border-color: #00bcd4;
}
.radio.radio-info input[type=radio]:checked + .cr:after {
  background: #00bcd4;
}
.radio.radio-purple input[type=radio]:checked + .cr:before {
  border-color: #7759de;
}
.radio.radio-purple input[type=radio]:checked + .cr:after {
  background: #7759de;
}
.radio .cr {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .radio input[type=radio] + .cr::after {
    top: 14px;
  }
}
.custom-controls-stacked .radio input[type=radio] + .cr:after {
  top: 15px;
}

/**====== Radio & Checked css end ======**/
/**  =====================
      Switches css start
==========================  **/
.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  margin-bottom: 0.5rem;
}

.switch input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.switch input[type=checkbox] + .cr {
  position: relative;
  display: inline-block;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  top: 12px;
}
.switch input[type=checkbox] + .cr:after, .switch input[type=checkbox] + .cr:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
.switch input[type=checkbox] + .cr:before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
}
.switch input[type=checkbox] + .cr:after {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}
.switch input[type=checkbox]:checked + .cr:before {
  background: #4099ff;
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.switch input[type=checkbox]:checked + .cr:after {
  left: 16px;
}
.switch input[type=checkbox]:disabled + label {
  opacity: 0.5;
  filter: grayscale(0.4);
  cursor: not-allowed;
}
.switch.switch-primary input[type=checkbox]:checked + .cr:before {
  background: #4099ff;
}
.switch.switch-danger input[type=checkbox]:checked + .cr:before {
  background: #ff5370;
}
.switch.switch-success input[type=checkbox]:checked + .cr:before {
  background: #2ed8b6;
}
.switch.switch-warning input[type=checkbox]:checked + .cr:before {
  background: #ffb64d;
}
.switch.switch-info input[type=checkbox]:checked + .cr:before {
  background: #00bcd4;
}
.switch.switch-purple input[type=checkbox]:checked + .cr:before {
  background: #7759de;
}
.switch.switch-alternative input[type=checkbox]:checked + .cr:before {
  background: #4099ff;
}

/**====== Switches css end ======**/
/**  =====================
      Badges css start
==========================  **/
.badge {
  font-size: 80%;
  padding: 0.35em 0.6em;
  font-weight: 600;
}

.badge-light-primary {
  background: rgba(64, 153, 255, 0.2);
  border-color: #cae2ff;
  color: #4099ff;
}

a.badge-light-primary.active, a.badge-light-primary:active, a.badge-light-primary:focus, a.badge-light-primary:hover {
  background: #4099ff;
  border-color: #4099ff;
  color: #d9ebff;
}

.badge-light-secondary {
  background: rgba(108, 117, 125, 0.2);
  border-color: #d6d8db;
  color: #6c757d;
}

a.badge-light-secondary.active, a.badge-light-secondary:active, a.badge-light-secondary:focus, a.badge-light-secondary:hover {
  background: #6c757d;
  border-color: #6c757d;
  color: #e2e3e5;
}

.badge-light-success {
  background: rgba(46, 216, 182, 0.2);
  border-color: #c4f4eb;
  color: #2ed8b6;
}

a.badge-light-success.active, a.badge-light-success:active, a.badge-light-success:focus, a.badge-light-success:hover {
  background: #2ed8b6;
  border-color: #2ed8b6;
  color: #d5f7f0;
}

.badge-light-info {
  background: rgba(0, 188, 212, 0.2);
  border-color: #b8ecf3;
  color: #00bcd4;
}

a.badge-light-info.active, a.badge-light-info:active, a.badge-light-info:focus, a.badge-light-info:hover {
  background: #00bcd4;
  border-color: #00bcd4;
  color: #ccf2f6;
}

.badge-light-warning {
  background: rgba(255, 182, 77, 0.2);
  border-color: blanchedalmond;
  color: #ffb64d;
}

a.badge-light-warning.active, a.badge-light-warning:active, a.badge-light-warning:focus, a.badge-light-warning:hover {
  background: #ffb64d;
  border-color: #ffb64d;
  color: #fff0db;
}

.badge-light-danger {
  background: rgba(255, 83, 112, 0.2);
  border-color: #ffcfd7;
  color: #ff5370;
}

a.badge-light-danger.active, a.badge-light-danger:active, a.badge-light-danger:focus, a.badge-light-danger:hover {
  background: #ff5370;
  border-color: #ff5370;
  color: #ffdde2;
}

.badge-light-light {
  background: rgba(248, 249, 250, 0.2);
  border-color: #fdfdfe;
  color: #f8f9fa;
}

a.badge-light-light.active, a.badge-light-light:active, a.badge-light-light:focus, a.badge-light-light:hover {
  background: #f8f9fa;
  border-color: #f8f9fa;
  color: #fefefe;
}

.badge-light-dark {
  background: rgba(52, 58, 64, 0.2);
  border-color: #c6c8ca;
  color: #343a40;
}

a.badge-light-dark.active, a.badge-light-dark:active, a.badge-light-dark:focus, a.badge-light-dark:hover {
  background: #343a40;
  border-color: #343a40;
  color: #d6d8d9;
}

/**====== Badges css end ======**/
.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  font-size: 14px;
  font-weight: 500;
}

.input-group-text svg {
  width: 18px;
  height: 18px;
}

.form-control-color-picker {
  height: 43px;
  padding: 0.5rem;
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .input-default input {
    width: 150px;
  }
}
select.form-control {
  appearance: none;
  background: #ffffff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 25px;
}
select.form-control[data-multiselectsplitter-firstselect-selector], select.form-control[data-multiselectsplitter-secondselect-selector] {
  background: none;
}
@media (max-width: 1300px) and (min-width: 1200px) {
  select.form-control {
    font-size: 0.8rem;
  }
}

.form-floating > label {
  top: 1px;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #6c757d;
}
.form-floating > .form-control:focus ~ label {
  color: #4099ff;
}
.form-floating > input {
  color: #222;
}

.bootstrap-select -select > .dropdown-toggle.btn-light,
.bootstrap-select -select > .dropdown-toggle.btn-secondary,
.bootstrap-select -select > .dropdown-toggle.btn-default {
  border-color: #ced4da !important;
  box-shadow: none;
  background: #ffffff !important;
  color: #495057;
}
.bootstrap-select -select > .dropdown-toggle.btn-light:focus,
.bootstrap-select -select > .dropdown-toggle.btn-secondary:focus,
.bootstrap-select -select > .dropdown-toggle.btn-default:focus {
  outline: none !important;
}

.form-check label {
  cursor: pointer;
}
.form-check label input {
  cursor: pointer;
}
.form-check .form-check-input.input-primary:checked {
  border-color: #4099ff;
  background-color: #4099ff;
}
.form-check .form-check-input.input-light-primary:checked {
  border-color: #d9ebff;
  background-color: #d9ebff;
}
.form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%234099ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%234099ff'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-primary:focus[type=checkbox], .form-check .form-check-input.input-primary:focus[type=radio], .form-check .form-check-input.input-light-primary:focus[type=checkbox], .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
}
.form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%234099ff'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-secondary:checked {
  border-color: #6c757d;
  background-color: #6c757d;
}
.form-check .form-check-input.input-light-secondary:checked {
  border-color: #e2e3e5;
  background-color: #e2e3e5;
}
.form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236c757d'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-secondary:focus[type=checkbox], .form-check .form-check-input.input-secondary:focus[type=radio], .form-check .form-check-input.input-light-secondary:focus[type=checkbox], .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}
.form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c757d'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-success:checked {
  border-color: #2ed8b6;
  background-color: #2ed8b6;
}
.form-check .form-check-input.input-light-success:checked {
  border-color: #d5f7f0;
  background-color: #d5f7f0;
}
.form-check .form-check-input.input-light-success:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232ed8b6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-success:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232ed8b6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-success:focus[type=checkbox], .form-check .form-check-input.input-success:focus[type=radio], .form-check .form-check-input.input-light-success:focus[type=checkbox], .form-check .form-check-input.input-light-success:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
}
.form-check.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232ed8b6'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-info:checked {
  border-color: #00bcd4;
  background-color: #00bcd4;
}
.form-check .form-check-input.input-light-info:checked {
  border-color: #ccf2f6;
  background-color: #ccf2f6;
}
.form-check .form-check-input.input-light-info:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300bcd4' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-info:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2300bcd4'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-info:focus[type=checkbox], .form-check .form-check-input.input-info:focus[type=radio], .form-check .form-check-input.input-light-info:focus[type=checkbox], .form-check .form-check-input.input-light-info:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.form-check.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300bcd4'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-warning:checked {
  border-color: #ffb64d;
  background-color: #ffb64d;
}
.form-check .form-check-input.input-light-warning:checked {
  border-color: #fff0db;
  background-color: #fff0db;
}
.form-check .form-check-input.input-light-warning:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffb64d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-warning:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffb64d'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-warning:focus[type=checkbox], .form-check .form-check-input.input-warning:focus[type=radio], .form-check .form-check-input.input-light-warning:focus[type=checkbox], .form-check .form-check-input.input-light-warning:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(255, 182, 77, 0.25);
}
.form-check.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffb64d'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-danger:checked {
  border-color: #ff5370;
  background-color: #ff5370;
}
.form-check .form-check-input.input-light-danger:checked {
  border-color: #ffdde2;
  background-color: #ffdde2;
}
.form-check .form-check-input.input-light-danger:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ff5370' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-danger:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff5370'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-danger:focus[type=checkbox], .form-check .form-check-input.input-danger:focus[type=radio], .form-check .form-check-input.input-light-danger:focus[type=checkbox], .form-check .form-check-input.input-light-danger:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
}
.form-check.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff5370'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-light:checked {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}
.form-check .form-check-input.input-light-light:checked {
  border-color: #fefefe;
  background-color: #fefefe;
}
.form-check .form-check-input.input-light-light:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-light:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f8f9fa'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light:focus[type=checkbox], .form-check .form-check-input.input-light:focus[type=radio], .form-check .form-check-input.input-light-light:focus[type=checkbox], .form-check .form-check-input.input-light-light:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25);
}
.form-check.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8f9fa'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-dark:checked {
  border-color: #343a40;
  background-color: #343a40;
}
.form-check .form-check-input.input-light-dark:checked {
  border-color: #d6d8d9;
  background-color: #d6d8d9;
}
.form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23343a40'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-dark:focus[type=checkbox], .form-check .form-check-input.input-dark:focus[type=radio], .form-check .form-check-input.input-light-dark:focus[type=checkbox], .form-check .form-check-input.input-light-dark:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.form-check.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23343a40'/%3e%3c/svg%3e");
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-right: none;
}

.form-v1 .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.form-v1 .form-label-group > input,
.form-v1 .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}
.form-v1 .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.form-v1 .form-label-group input::-webkit-input-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::-moz-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::placeholder {
  color: transparent;
}
.form-v1 .form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-v1 .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-v1 .form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}
.form-v1 .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.datepicker {
  width: 265px;
  padding: 10px;
}
.datepicker.dropdown-menu {
  padding: 10px;
}
.datepicker.datepicker-orient-top {
  margin-top: 8px;
}
.datepicker table {
  width: 100%;
}
.datepicker td,
.datepicker th {
  font-weight: regular;
  width: 35px;
  height: 35px;
}
.datepicker thead th {
  color: inherit;
}
.datepicker thead th.datepicker-switch, .datepicker thead th.next, .datepicker thead th.prev {
  font-weight: 500;
  color: #222;
}
.datepicker thead th.datepicker-switch i, .datepicker thead th.next i, .datepicker thead th.prev i {
  font-size: 1.2rem;
  color: #222;
}
.datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before, .datepicker thead th.prev i:before {
  line-height: 0;
  vertical-align: middle;
}
.datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover, .datepicker thead th.prev:hover {
  background: #f6f7fb !important;
}
.datepicker thead th.dow {
  color: #222;
  font-weight: 500;
}
.datepicker tbody tr > td.day {
  color: #222;
}
.datepicker tbody tr > td.day:hover {
  background: #f6f7fb;
  color: #222;
}
.datepicker tbody tr > td.day.old {
  color: #222;
}
.datepicker tbody tr > td.day.new {
  color: #222;
}
.datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover, .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover {
  background: #4099ff;
  color: #fff;
}
.datepicker tbody tr > td.day.today {
  position: relative;
  background: #4099ff !important;
  color: #fff !important;
}
.datepicker tbody tr > td.day.today:before {
  content: "";
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #ffffff;
  border-top-color: #222;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.datepicker tbody tr > td.day.range {
  background: #f6f7fb;
}
.datepicker tbody tr > td span.hour,
.datepicker tbody tr > td span.minute,
.datepicker tbody tr > td span.month,
.datepicker tbody tr > td span.year {
  color: #222;
}
.datepicker tbody tr > td span.hour:hover,
.datepicker tbody tr > td span.minute:hover,
.datepicker tbody tr > td span.month:hover,
.datepicker tbody tr > td span.year:hover {
  background: #f6f7fb;
}
.datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover,
.datepicker tbody tr > td span.minute.active,
.datepicker tbody tr > td span.minute.active.focused:hover,
.datepicker tbody tr > td span.minute.active:hover,
.datepicker tbody tr > td span.minute.focused,
.datepicker tbody tr > td span.minute.focused:hover,
.datepicker tbody tr > td span.month.active,
.datepicker tbody tr > td span.month.active.focused:hover,
.datepicker tbody tr > td span.month.active:hover,
.datepicker tbody tr > td span.month.focused,
.datepicker tbody tr > td span.month.focused:hover,
.datepicker tbody tr > td span.year.active,
.datepicker tbody tr > td span.year.active.focused:hover,
.datepicker tbody tr > td span.year.active:hover,
.datepicker tbody tr > td span.year.focused,
.datepicker tbody tr > td span.year.focused:hover {
  background: #4099ff;
  color: #fff;
}
.datepicker tfoot tr > th.clear, .datepicker tfoot tr > th.today {
  font-weight: 500;
}
.datepicker tfoot tr > th.clear:hover, .datepicker tfoot tr > th.today:hover {
  background: #f6f7fb;
}

.bootstrap-timepicker-widget {
  left: auto;
  width: 175px;
}
.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  border: none;
  box-shadow: none;
}
.bootstrap-timepicker-widget table td > a {
  border: 0;
}
.bootstrap-timepicker-widget table td > a i {
  font-size: 1.1rem;
}

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  color: inherit;
}
.bootstrap-timepicker-widget table td > a:hover {
  background: #f6f7fb;
}

.tagify__tag {
  --tag-bg: #f6f7fb;
  --tag-hover: rgba(64, 153, 255, 0.3);
  --tag-remove-bg: rgba(255, 83, 112, 0.3);
  --tag-remove-btn-bg--hover: #ff5370;
}
.tagify__tag.tagify__tag--primary {
  --tag-bg: rgba(64, 153, 255, 0.2);
}
.tagify__tag.tagify__tag--primary div .tagify__tag-text {
  color: #4099ff;
}
.tagify__tag.tagify__tag--secondary {
  --tag-bg: rgba(108, 117, 125, 0.2);
}
.tagify__tag.tagify__tag--secondary div .tagify__tag-text {
  color: #6c757d;
}
.tagify__tag.tagify__tag--success {
  --tag-bg: rgba(46, 216, 182, 0.2);
}
.tagify__tag.tagify__tag--success div .tagify__tag-text {
  color: #2ed8b6;
}
.tagify__tag.tagify__tag--info {
  --tag-bg: rgba(0, 188, 212, 0.2);
}
.tagify__tag.tagify__tag--info div .tagify__tag-text {
  color: #00bcd4;
}
.tagify__tag.tagify__tag--warning {
  --tag-bg: rgba(255, 182, 77, 0.2);
}
.tagify__tag.tagify__tag--warning div .tagify__tag-text {
  color: #ffb64d;
}
.tagify__tag.tagify__tag--danger {
  --tag-bg: rgba(255, 83, 112, 0.2);
}
.tagify__tag.tagify__tag--danger div .tagify__tag-text {
  color: #ff5370;
}
.tagify__tag.tagify__tag--light {
  --tag-bg: rgba(248, 249, 250, 0.2);
}
.tagify__tag.tagify__tag--light div .tagify__tag-text {
  color: #f8f9fa;
}
.tagify__tag.tagify__tag--dark {
  --tag-bg: rgba(52, 58, 64, 0.2);
}
.tagify__tag.tagify__tag--dark div .tagify__tag-text {
  color: #343a40;
}

.bootstrap-switch {
  border-color: #e3eaef;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: #f6f7fb;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #fff;
  background: #4099ff;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
  color: #fff;
  background: #6c757d;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #fff;
  background: #2ed8b6;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #fff;
  background: #00bcd4;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #fff;
  background: #ffb64d;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #fff;
  background: #ff5370;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
  color: #fff;
  background: #f8f9fa;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
  color: #fff;
  background: #343a40;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn[class*=btn-]:not(.btn-light) {
  color: #fff;
}

.typeahead .twitter-typeahead {
  display: block !important;
}

.scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}

.tt-menu {
  padding: 10px 0;
  font-size: 1rem;
  background: #fff;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.tt-menu .tt-suggestion {
  padding: 4px 20px;
  border-radius: 2px;
}
.tt-menu .tt-suggestion.active, .tt-menu .tt-suggestion:active, .tt-menu .tt-suggestion:focus, .tt-menu .tt-suggestion:hover {
  background: rgba(64, 153, 255, 0.03);
  color: #4099ff;
}

.sticky-action {
  top: 56px;
  position: sticky;
  z-index: 1020;
  background: var(--bs-card-bg);
}

.dropzone {
  min-height: auto;
}

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #e3eaef;
  border-radius: 5px;
}
.dropzone .dropzone-msg-title {
  color: #222;
  margin: 0 0 5px;
  padding: 0;
  font-weight: 500;
  font-size: 1.2rem;
}
.dropzone .dropzone-msg-desc {
  color: #222;
  font-weight: 400;
  font-size: 1rem;
}
.dropzone .dz-preview .dz-image {
  border-radius: 5px;
}
.dropzone.dropzone-primary {
  border-color: #4099ff;
}
.dropzone.dropzone-secondary {
  border-color: #6c757d;
}
.dropzone.dropzone-success {
  border-color: #2ed8b6;
}
.dropzone.dropzone-info {
  border-color: #00bcd4;
}
.dropzone.dropzone-warning {
  border-color: #ffb64d;
}
.dropzone.dropzone-danger {
  border-color: #ff5370;
}
.dropzone.dropzone-light {
  border-color: #f8f9fa;
}
.dropzone.dropzone-dark {
  border-color: #343a40;
}

.dz-started .dropzone-msg {
  display: none;
}

.dropzone-multi {
  border: 0;
  padding: 0;
}
.dropzone-multi .dz-message {
  display: none;
}
.dropzone-multi .dropzone-panel .dropzone-remove-all,
.dropzone-multi .dropzone-panel .dropzone-upload {
  display: none;
}
.dropzone-multi .dropzone-item {
  background: #f6f7fb;
  border-radius: 5px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
}
.dropzone-multi .dropzone-item .dropzone-progress {
  width: 20%;
}
.dropzone-multi .dropzone-item .dropzone-progress .progress {
  height: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
  font-size: 0.9rem;
  font-weight: 500;
  color: #222;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
  font-size: 0.9rem;
  font-weight: 500;
  color: #222;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #ff5370;
  text-overflow: ellipsis;
}
.dropzone-multi .dropzone-item .dropzone-toolbar {
  margin-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
  font-size: 0.8rem;
  color: inherit;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
  color: #4099ff;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
  transition: all 0.2s ease-in-out;
}

.uppy-Dashboard--modal {
  z-index: 1030;
}
.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1030;
}
.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1031;
}

.uppy-DragDrop--is-dragdrop-supported {
  border-color: #f6f7fb;
}

.pc-uppy-thumbnail-container .pc-uppy-thumbnail img {
  width: 100px;
}

.slider-selection {
  box-shadow: none;
}

.pc-toggle-noUiSlider {
  height: 50px;
}

.pc-toggle-noUiSlider.off .noUi-handle {
  border-color: #ff5370;
  background: #ff5370;
  box-shadow: none;
}

.note-editor.card .card-header {
  padding: 0 5px 5px;
  border-bottom-color: #a9a9a9;
}

#cke5-inline-demo .ck-content {
  margin-bottom: 1rem;
  padding: 2.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#cke5-inline-demo .ck-content h3, #cke5-inline-demo .ck-content .h3 {
  margin-top: 0;
}
#cke5-inline-demo header.ck-content {
  text-align: center;
}
#cke5-inline-demo header.ck-content h2:first-of-type, #cke5-inline-demo header.ck-content .h2:first-of-type {
  margin-top: 0;
}
#cke5-inline-demo header.ck-content h2 + h3, #cke5-inline-demo header.ck-content .h2 + h3, #cke5-inline-demo header.ck-content h2 + .h3, #cke5-inline-demo header.ck-content .h2 + .h3 {
  margin-top: 0;
  color: #222;
  font-weight: 600;
}
#cke5-inline-demo .demo-row {
  width: 100%;
  display: flex;
}
#cke5-inline-demo .demo-row .demo-row__half {
  width: 50%;
}
#cke5-inline-demo .demo-row .demo-row__half:first-of-type {
  padding-right: 0.5rem;
}
#cke5-inline-demo .demo-row .demo-row__half:last-of-type {
  padding-left: 0.5rem;
}
#cke5-inline-demo .demo-row p {
  margin-bottom: 0;
}
#cke5-inline-demo .demo-row h3, #cke5-inline-demo .demo-row .h3 {
  margin: 0;
  font-weight: 600;
}

.switch-demo .custom-switch-v1 {
  margin-bottom: 4px;
}

.custom-switch-v1.form-switch {
  padding-left: 2.9em;
}
.custom-switch-v1.form-switch .form-check-input {
  height: 20px;
  width: 35px;
  margin-left: -2.9em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.custom-switch-v1.form-switch .form-check-input[class*=input-light-] {
  border: none;
}
.custom-switch-v1.form-switch .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.custom-switch-v1.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%234099ff'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%236c757d'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%232ed8b6'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2300bcd4'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffb64d'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ff5370'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f8f9fa'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23343a40'/%3e%3c/svg%3e");
}
.custom-switch-v1 .custom-control-label::before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 0.8rem;
  top: 0;
  left: -2.55rem;
}
.custom-switch-v1 .custom-control-label::after {
  top: calc(0.15625rem - 2px);
  left: calc(-2.25rem - 4px);
  height: 19px;
  width: 19px;
  border-radius: 0.7rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.custom-switch-v1 .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.95rem);
}

.row[data-multiselectsplitter-wrapper-selector] select {
  margin-bottom: 8px;
}

.choices {
  position: relative;
  margin-bottom: 5px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #e9ecef;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ced4da;
  background-color: #ffffff;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*=select-one] .choices__button:focus, .choices[data-type*=select-one] .choices__button:hover {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px #4099ff;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #495057 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open:after {
  border-color: transparent transparent #495057 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:focus, .choices[data-type*=select-multiple] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #ffffff;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-width: 1px;
  border-color: #4099ff;
  box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
}
.is-open .choices__inner {
  border-radius: 5px 5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 5px 5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #4099ff;
  border: 1px solid #278bff;
  color: #ffffff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #278bff;
  border: 1px solid #0d7eff;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #9facb9;
  border: 1px solid #8293a4;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.choices__list--dropdown.is-active {
  visibility: visible;
}
.is-open .choices__list--dropdown {
  border-color: #a2aeb9;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: rgba(64, 153, 255, 0.1);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #ebeef0;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #ffffff;
  font-size: 14px;
  margin-bottom: 0px;
  border: 0;
  color: #495057;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

.typeahead {
  position: relative;
}
.typeahead > ul {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
  display: none;
  float: left;
  font-size: 14px;
  left: 0;
  list-style: none;
  margin: 2px 0 0;
  min-width: 170px;
  padding: 5px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}
.typeahead > ul > li > a {
  clear: both;
  color: #333;
  cursor: default;
  display: block;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 3px 20px;
  white-space: nowrap;
}
.typeahead > ul > li.active > a,
.typeahead > ul > li > a:hover {
  background-color: #3f9bfa;
  color: #ffffff;
  text-decoration: none;
}

.validate-me .error {
  border-color: #ff5370;
}

.error-message {
  color: #ff5370;
}

.flatpickr-input[readonly] {
  background: #ffffff;
}

.dropdown-toggle.arrow-none:after {
  display: none;
}

.dropdown-menu {
  padding: 10px 0;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  border: none;
  border-radius: 2px;
}

.pc-header .dropdown-menu {
  animation: 0.4s ease-in-out 0s normal forwards 1 fadein;
}

@keyframes fadein {
  from {
    transform: translate3d(0, 8px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.dropdown .dropdown-item.active i.material-icons-two-tone, .dropdown .dropdown-item:active i.material-icons-two-tone, .dropdown .dropdown-item:focus i.material-icons-two-tone, .dropdown .dropdown-item:hover i.material-icons-two-tone {
  background-color: #16181b;
}

.dropdown .dropdown-item {
  padding: 10px 25px;
}
.dropdown .dropdown-item i {
  font-size: 18px;
  margin-right: 10px;
}
.dropdown .dropdown-item i.material-icons-two-tone {
  vertical-align: bottom;
  font-size: 22px;
}
.dropdown .dropdown-item svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  fill: #f2f2f2;
}
.dropdown .dropdown-item .float-end svg {
  width: 14px;
  height: 14px;
}

/* new logo start */
.b-brand {
  display: flex;
  align-items: center;
}

.navbar-collapsed .b-brand {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.navbar-collapsed .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.navbar-collapsed:hover .b-brand {
  transform: rotateY(0deg);
  opacity: 1;
}
.navbar-collapsed:hover .mobile-menu {
  transition-delay: 0.15s;
  transform: rotateY(0deg);
  opacity: 1;
}

/* new logo End  */
.search-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 1030;
  background: #fff;
  padding: 12px;
}
.search-bar .close {
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 8px 16px;
}

.pcoded-header {
  z-index: 1029;
  position: relative;
  display: flex;
  min-height: 56px;
  padding: 0;
  top: 0;
  background: #fff;
  color: rgba(59, 70, 80, 0.8);
  width: 100%;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
}
.pcoded-header .m-header {
  width: 235px;
  position: relative;
  height: 56px;
  display: inline-flex;
  align-items: center;
  padding: 0 25px;
}
.pcoded-header .m-header .logo-dark,
.pcoded-header .m-header .logo-thumb {
  display: none;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text {
  margin-right: 0;
}
.pcoded-header .input-group .input-group-text,
.pcoded-header a,
.pcoded-header dropdown-toggle {
  color: rgba(59, 70, 80, 0.8);
}
.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header dropdown-toggle:hover {
  color: #3b4650;
}
.pcoded-header .navbar-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: inline-block;
}
.pcoded-header .navbar-nav > li {
  line-height: 56px;
  display: inline-block;
  padding: 0 12px;
}
.pcoded-header .navbar-nav > li > a > i,
.pcoded-header .navbar-nav > li .dropdown-toggle > i {
  font-size: 16px;
}
.pcoded-header .navbar-nav > li > a .badge,
.pcoded-header .navbar-nav > li .dropdown-toggle .badge {
  border-radius: 100px;
  right: 0;
  position: absolute;
  top: 14px;
  padding: 4px;
}
.pcoded-header .navbar-nav > li > a .badge:after, .pcoded-header .navbar-nav > li > a .badge:before,
.pcoded-header .navbar-nav > li .dropdown-toggle .badge:after,
.pcoded-header .navbar-nav > li .dropdown-toggle .badge:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4);
}
.pcoded-header .navbar-nav > li > a .badge:after,
.pcoded-header .navbar-nav > li .dropdown-toggle .badge:after {
  animation: not-blink 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.pcoded-header .navbar-nav > li > a .badge:before,
.pcoded-header .navbar-nav > li .dropdown-toggle .badge:before {
  animation: not-blink 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1.15s;
}
.pcoded-header .navbar-nav > li .dropdown.show .badge {
  display: none;
}
.pcoded-header .navbar-nav > li .nav-link {
  padding: 0;
}
.pcoded-header .navbar-nav > li:first-child {
  padding-left: 25px;
}
.pcoded-header .navbar-nav > li:last-child {
  padding-right: 25px;
}
.pcoded-header .me-auto .dropdown-menu {
  margin-left: -20px;
}
.pcoded-header .ms-auto {
  float: right;
}
.pcoded-header .ms-auto .dropdown-menu {
  margin-right: -20px;
}
.pcoded-header .main-search .input-group {
  border-radius: 3px;
  padding: 0;
}
.pcoded-header .main-search .input-group .form-control,
.pcoded-header .main-search .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  height: auto;
}
.pcoded-header .main-search .input-group .search-close {
  display: none;
}
.pcoded-header .main-search .input-group .search-btn {
  padding: 0;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
  margin-right: 0;
}
.pcoded-header .main-search .input-group .form-control {
  transition: all 0.15s ease-in-out;
  font-size: 14px;
}
.pcoded-header .main-search .input-group .form-control:active, .pcoded-header .main-search .input-group .form-control:focus, .pcoded-header .main-search .input-group .form-control:hover,
.pcoded-header .main-search .input-group .search-btn:active,
.pcoded-header .main-search .input-group .search-btn:focus,
.pcoded-header .main-search .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}
.pcoded-header .main-search.open .input-group {
  background: #f6f7fb;
  border: 0 solid #f6f7fb;
  padding: 0 0 0 20px;
}
.pcoded-header .main-search.open .input-group .search-close {
  display: flex;
}
.pcoded-header .main-search.open .input-group .search-btn {
  padding: 6px 9px;
  margin-left: 5px;
  color: #fff;
  background: #4099ff;
  border-color: #4099ff;
}
.pcoded-header .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header .main-search.open .input-group .form-control {
  transition: all 0.15s ease-in-out;
}
.pcoded-header .main-search.open .input-group .form-control:active, .pcoded-header .main-search.open .input-group .form-control:focus, .pcoded-header .main-search.open .input-group .form-control:hover,
.pcoded-header .main-search.open .input-group .search-btn:active,
.pcoded-header .main-search.open .input-group .search-btn:focus,
.pcoded-header .main-search.open .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}
.pcoded-header .dropdown .dropdown-toggle {
  line-height: 56px;
  display: inline-block;
  padding-right: 5px;
}
.pcoded-header .dropdown .dropdown-toggle:after {
  display: none;
}
.pcoded-header .dropdown .dropdown-menu {
  position: absolute;
  min-width: 250px;
}
.pcoded-header .dropdown .dropdown-menu li {
  line-height: 1.2;
}
.pcoded-header .dropdown .dropdown-menu li a {
  padding: 10px;
  font-size: 14px;
}
.pcoded-header .dropdown .dropdown-menu li.active a, .pcoded-header .dropdown .dropdown-menu li:active a, .pcoded-header .dropdown .dropdown-menu li:focus a, .pcoded-header .dropdown .dropdown-menu li:hover a {
  color: #222;
}
.pcoded-header .dropdown .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .notification a {
  color: #222;
}
.pcoded-header .dropdown .notification a:hover {
  color: #222;
}
.pcoded-header .dropdown .notification .noti-head {
  background: #3b4650;
  padding: 15px 20px;
}
.pcoded-header .dropdown .notification .noti-head h6, .pcoded-header .dropdown .notification .noti-head .h6 {
  color: #fff;
}
.pcoded-header .dropdown .notification .noti-head a {
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body {
  padding: 0;
  position: relative;
  height: 300px;
}
.pcoded-header .dropdown .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}
.pcoded-header .dropdown .notification .noti-body li {
  padding: 15px 20px;
  transition: all 0.15s ease-in-out;
}
.pcoded-header .dropdown .notification .noti-body li.n-title {
  padding-bottom: 0;
}
.pcoded-header .dropdown .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}
.pcoded-header .dropdown .notification .noti-body li.notification:hover {
  background: rgba(64, 153, 255, 0.1);
}
.pcoded-header .dropdown .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body li p strong {
  color: #222;
}
.pcoded-header .dropdown .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}
.pcoded-header .dropdown .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
  background: #f6f7fb;
}
.pcoded-header .dropdown .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .notification ul li {
  padding: 20px 15px;
}
.pcoded-header .dropdown .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  padding: 15px;
  position: relative;
  background: #3b4650;
}
.pcoded-header .dropdown .profile-notification .pro-head img {
  width: 40px;
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pcoded-header .dropdown .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .profile-notification .pro-body li {
  margin: 0 10px;
  border-radius: 2px;
}
.pcoded-header .dropdown .profile-notification .pro-body li a {
  color: #222;
  font-size: 14px;
  padding: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-body li a i {
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-body li.active, .pcoded-header .dropdown .profile-notification .pro-body li:active, .pcoded-header .dropdown .profile-notification .pro-body li:focus, .pcoded-header .dropdown .profile-notification .pro-body li:hover {
  background: #4099ff;
  box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
}
.pcoded-header .dropdown .profile-notification .pro-body li.active > a, .pcoded-header .dropdown .profile-notification .pro-body li:active > a, .pcoded-header .dropdown .profile-notification .pro-body li:focus > a, .pcoded-header .dropdown .profile-notification .pro-body li:hover > a {
  color: #fff;
  background: transparent;
}

@keyframes not-blink {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
/* header css end */
/* menu[ vartical ] css start */
.mob-toggler,
.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 56px;
  position: absolute;
  left: 15px;
  top: 0;
  padding: 0 10px;
}
.mob-toggler span,
.mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgba(59, 70, 80, 0.8);
  transition: 0.15s cubic-bezier(0.97, 0.75, 0.61, 1.84);
  backface-visibility: hidden;
  border-radius: 2px;
}
.mob-toggler span:after, .mob-toggler span:before,
.mobile-menu span:after,
.mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  background-color: rgba(59, 70, 80, 0.8);
  transition: 0.15s cubic-bezier(0.97, 0.75, 0.61, 1.84);
  backface-visibility: hidden;
  border-radius: 2px;
}
.mob-toggler span:after,
.mobile-menu span:after {
  top: 6px;
  width: 70%;
}
.mob-toggler span:before,
.mobile-menu span:before {
  top: -6px;
  width: 40%;
}
.mob-toggler:hover span,
.mobile-menu:hover span {
  background-color: #3b4650;
}
.mob-toggler:hover span:after, .mob-toggler:hover span:before,
.mobile-menu:hover span:after,
.mobile-menu:hover span:before {
  background-color: #3b4650;
  width: 100%;
}

.header-mobile-collapse {
  align-items: center;
  justify-content: center;
  width: 37px;
  position: absolute;
  padding: 0 10px;
}
.header-mobile-collapse i {
  font-size: 18px;
}

.mob-toggler {
  right: 5px;
  display: none;
}

.navbar-brand {
  background: #4099ff;
}

.pcoded-header:before,
.pcoded-main-container:before {
  content: "";
  transition: all 0.15s ease-in-out;
}

.pcoded-navbar {
  display: block;
  z-index: 1028;
  position: absolute;
  box-shadow: 2px 0 2.94px 0.06px rgba(4, 26, 55, 0.16);
  transition: all 0.05s ease-in-out;
  width: 235px;
  height: calc(100% - 56px);
  margin-top: 56px;
  top: 0;
  background: #3b4650;
  color: #b9c7d4;
}
.pcoded-navbar ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.pcoded-navbar .scroll-div.navbar-content {
  height: 100%;
}
.pcoded-navbar .version {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 16px 0px;
  cursor: pointer;
}
.pcoded-navbar .version label {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
  background-color: rgb(250, 250, 250);
  color: rgb(158, 158, 158);
  border-radius: 16px;
}
.pcoded-navbar .header-logo {
  position: relative;
  align-items: center;
  display: inline-flex;
  float: left;
  background: #3b4650;
  height: 56px;
  text-align: center;
  width: 235px;
  margin-right: 0;
  padding: 10px 25px;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar .header-logo .logo-dark {
  display: none;
}
.pcoded-navbar .header-logo .logo-thumb {
  transform: rotateY(-90deg);
  opacity: 0;
  position: absolute;
  transition: unset;
}
.pcoded-navbar .header-logo + .scroll-div {
  float: left;
  display: inline-block;
}
.pcoded-navbar .mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 56px;
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar .pcoded-badge {
  font-size: 75%;
  position: relative;
  right: 0;
  top: -1px;
  padding: 2px 7px;
  border-radius: 2px;
  display: inline-block;
  margin-left: 10px;
}
.pcoded-navbar .pcoded-inner-navbar {
  flex-direction: column;
}
.pcoded-navbar .pcoded-inner-navbar li {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a {
  text-align: left;
  padding: 7px 20px;
  margin: 5px 0 0;
  border-radius: 2px;
  display: block;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: transparent;
}
.pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
  font-size: 16px;
  padding: 0;
  margin-right: 5px;
  border-radius: 4px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  text-align: center;
  justify-content: center;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
  position: relative;
  top: 0;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e844";
  font-family: "feather";
  font-size: 13px;
  border: none;
  position: absolute;
  top: 13px;
  right: 20px;
  transition: 0.15s ease-in-out;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  transform: rotate(90deg);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu a:after {
  top: 7px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > .pcoded-submenu {
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > a {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu:after {
  content: "";
  position: absolute;
  top: 0;
  left: 25px;
  width: 1px;
  height: calc(100% - 36px);
  background: rgba(185, 199, 212, 0.2);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 26px;
  width: 20px;
  height: 1px;
  background: rgba(185, 199, 212, 0.2);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  text-align: left;
  padding: 10px 7px 10px 60px;
  margin: 0;
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 18px;
  left: 44px;
  width: 6px;
  height: 6px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu:after {
  left: 45px;
  top: -20px;
  height: calc(100% - 15px);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li:after {
  left: 46px;
  width: 20px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li > a {
  padding: 10px 7px 10px 80px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li > a:before {
  left: 65px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 18px 15px 5px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-top: 1px solid #496178;
  margin-top: 10px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption:first-child {
  border-top: none;
  margin-top: 0;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption > label {
  margin-bottom: 0;
}
.pcoded-navbar .pcoded-inner-navbar li.disabled > a {
  cursor: default;
  opacity: 0.5;
}
.pcoded-navbar .pcoded-submenu {
  background: #3b4650;
  padding: 15px 0;
}
.pcoded-navbar a {
  color: #b9c7d4;
}
.pcoded-navbar .navbar-content,
.pcoded-navbar .navbar-wrapper {
  width: 100%;
  height: 100%;
}
.pcoded-navbar.navbar-collapsed {
  width: 70px;
  height: 100%;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .header-logo {
  width: 70px;
  padding: 10px 20px;
}
.pcoded-navbar.navbar-collapsed .header-logo img {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: unset;
}
.pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
  transform: rotateY(0deg);
  transform-origin: 0 0;
  opacity: 1;
  left: calc((70px / 2) - 20px);
}
.pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed .navbar-content.ps {
  overflow: visible;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 32px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(185, 199, 212, 0.5);
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  padding: 7px 20px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext {
  position: absolute;
  top: 11px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
  right: 12px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
  color: transparent;
  white-space: nowrap;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li + li a {
  border-top: none;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed ~ .pcoded-main-container {
  margin-left: 70px;
}
.pcoded-navbar.navbar-collapsed .pcoded-badge {
  transform: rotateX(-90deg);
  transform-origin: 50% 50%;
  opacity: 0;
  display: none;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar.navbar-collapsed:hover {
  width: 235px !important;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo {
  width: 235px;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo img {
  transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo .logo-thumb {
  transform: rotateY(-90deg);
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo .mobile-menu {
  display: flex;
  right: 10px;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption > label {
  transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: transparent;
}
.pcoded-navbar.navbar-collapsed:hover .mobile-menu {
  transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li > a > .pcoded-mtext {
  transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li > a > .pcoded-mtext:after {
  opacity: 1;
  visibility: visible;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
  transform: rotateX(0deg);
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a:after {
  transform: rotateX(0deg) rotate(90deg);
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
  color: inherit;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 1;
}
.pcoded-navbar .pcoded-menu-caption {
  color: #8090a5;
}
.pcoded-navbar .pcoded-inner-navbar li.active > a, .pcoded-navbar .pcoded-inner-navbar li.pcoded-trigger > a {
  font-weight: 600;
}
.pcoded-navbar .pcoded-inner-navbar li.active > a, .pcoded-navbar .pcoded-inner-navbar li:focus > a, .pcoded-navbar .pcoded-inner-navbar li:hover > a {
  color: #e5efff;
}
.pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #e5efff;
}
.pcoded-navbar .pcoded-inner-navbar > li.active > a, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(202, 202, 202, 0.3);
  color: #e5efff;
}
.pcoded-navbar .pcoded-inner-navbar > li.active > a:before, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a:before {
  background-color: #4099ff;
}
.pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.active:after, .pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.pcoded-trigger:after {
  display: none;
}

.pcoded-navbar.mob-open ~ .pcoded-header:before,
.pcoded-navbar.mob-open ~ .pcoded-main-container:before, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* ===  scroll bar start  ===== */
.navbar-content {
  position: relative;
}

/* menu [ vertical ] css end */
/* main content start */
.pcoded-content {
  position: relative;
  display: block;
  padding: 25px;
}

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  top: 0;
  margin-left: 235px;
  min-height: 100vh;
  transition: all 0.15s ease-in-out;
}

/* main content end*/
/* ==========================    Responsive Menu  start   ====================== */
@media (min-width: 992px) {
  .m-header {
    z-index: 1027;
  }
  .m-header .header-mobile-collapse {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .mob-toggler,
  .mobile-menu {
    left: auto;
    right: 0;
  }
  .pcoded-header .input-group-text,
  .pcoded-header a,
  .pcoded-header dropdown-toggle {
    color: #3b4650;
  }
  .pcoded-header .input-group-text:hover,
  .pcoded-header a:hover,
  .pcoded-header dropdown-toggle:hover {
    color: #4099ff;
  }
  .pcoded-header .container > .collapse:not(.show),
  .pcoded-header > .collapse:not(.show) {
    background: #fff;
    box-shadow: 0 0 3px 0 rgba(54, 80, 138, 0.34);
    position: relative;
  }
  .pcoded-header .container > .collapse:not(.show) .mob-toggler:after,
  .pcoded-header > .collapse:not(.show) .mob-toggler:after {
    content: "\e89a";
    font-family: "feather";
    font-size: 20px;
    color: #3b4650;
    position: absolute;
    right: 10px;
    top: 0;
    width: 40px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pcoded-header .container > .collapse:not(.show) .me-auto,
  .pcoded-header > .collapse:not(.show) .me-auto {
    height: 56px;
    display: flex;
    justify-content: center;
  }
  .pcoded-header .container > .collapse:not(.show) .ms-auto,
  .pcoded-header > .collapse:not(.show) .ms-auto {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .pcoded-header .dropdown-menu,
  .pcoded-header .dropdown-menu a,
  .pcoded-header .search-close .input-group-text {
    color: #3b4650;
  }
  .pcoded-header .m-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
  }
  .pcoded-header .m-header .mobile-menu {
    display: none;
  }
  .pcoded-header .m-header .header-mobile-collapse {
    right: auto;
    left: 10px;
  }
  .pcoded-header .m-header .mob-toggler {
    display: flex;
  }
  .pcoded-header .full-screen {
    display: none;
  }
  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 20px;
  }
  .pcoded-navbar {
    margin-left: -235px;
    position: absolute;
    height: 100%;
    box-shadow: none;
  }
  .pcoded-navbar .scroll-div.navbar-content {
    height: 100%;
  }
  .pcoded-navbar ~ .pcoded-header,
  .pcoded-navbar ~ .pcoded-main-container {
    margin-left: 0;
  }
  .pcoded-navbar ~ .pcoded-header {
    width: 100%;
  }
  .pcoded-navbar .navbar-brand {
    display: none;
  }
  .pcoded-navbar.mob-open {
    margin-left: 0;
  }
  .pcoded-navbar.mob-open .pcoded-main-container ~ .pcoded-header,
  .pcoded-navbar.mob-open ~ .pcoded-main-container {
    margin-left: 0;
  }
  .pcoded-main-container {
    padding-top: 0;
  }
}
@media only screen and (max-width: 575px) {
  .pcoded-header .main-search.open .input-group .search-close {
    display: none;
  }
  .pcoded-header .navbar-nav > li {
    padding: 0 8px;
  }
  .pcoded-header .navbar-nav.ms-auto > li:first-child {
    padding-left: 0;
  }
  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 15px;
  }
  .pcoded-header .navbar-nav.me-auto li.nav-item:not(.dropdown) {
    display: block;
    padding-right: 0;
  }
  .pcoded-header .navbar-nav.me-auto li.nav-item:not(.dropdown) .search-close {
    display: none;
  }
  .pcoded-header .dropdown.show {
    position: static;
  }
  .pcoded-header .dropdown.show a:after {
    display: none;
  }
  .pcoded-header .dropdown.show:before {
    display: none;
  }
  .pcoded-header .dropdown .notification {
    width: 100%;
  }
  .pcoded-header .dropdown .dropdown-menu {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .header-chat.open,
  .header-user-list.open {
    width: 280px;
  }
  .pcoded-content {
    padding: 25px 15px;
  }
  .card {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .pcoded-navbar .header-logo {
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed {
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .header-logo img {
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
    transition: none;
    transition-delay: 0;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-badge {
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
    transition-delay: 0;
  }
}
.pcoded-navbar .card {
  background: rgba(255, 255, 255, 0.1);
  margin: 20px;
  box-shadow: 0 0 0 1px rgba(226, 229, 232, 0.15);
  color: rgba(255, 255, 255, 0.6);
}
.pcoded-navbar .card h6, .pcoded-navbar .card .h6 {
  color: rgba(255, 255, 255, 0.6);
}
.pcoded-navbar .card .close {
  position: absolute;
  color: #b9c7d4;
  position: absolute;
  top: 10px;
  right: 12px;
  opacity: 0.8;
  text-shadow: none;
}
.pcoded-navbar.menu-light .card {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px #f6f7fb;
  color: rgba(0, 0, 0, 0.6);
}
.pcoded-navbar.menu-light .card h6, .pcoded-navbar.menu-light .card .h6 {
  color: #666;
}
.pcoded-navbar.menu-light .card .close {
  color: #666;
}
.pcoded-navbar.navbar-collapsed .card {
  display: none;
}

/* ===================================================================================
 ==========================    Diffrent Layout Styles Start     ====================
 ===================================================================================
 ====== [ light menu style start ] ======== */
.pcoded-navbar.menu-light {
  background-color: #fff;
  color: #666;
}
.pcoded-navbar.menu-light .header-logo {
  background-color: #3b4650;
}
.pcoded-navbar.menu-light .header-logo .logo-dark {
  display: none;
}
.pcoded-navbar.menu-light .header-logo .logo-main {
  display: block;
}
.pcoded-navbar.menu-light .mobile-menu span {
  background-color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.menu-light .mobile-menu span:after, .pcoded-navbar.menu-light .mobile-menu span:before {
  background-color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.menu-light .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-menu-caption {
  border-top-color: #e2e5e8;
  color: #000;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu:after {
  background: rgba(185, 199, 212, 0.5);
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li:after {
  background: rgba(185, 199, 212, 0.5);
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before, .pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.pcoded-trigger > a:before, .pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  background: #4099ff;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li + li > a {
  border-top-color: #ededed;
}
.pcoded-navbar.menu-light .pcoded-submenu {
  background: #fff;
}
.pcoded-navbar.menu-light a {
  color: #666;
}
.pcoded-navbar.menu-light.navbar-collapsed .pcoded-menu-caption:after {
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-navbar.menu-light.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: transparent;
}
.pcoded-navbar.menu-light .pcoded-menu-caption {
  color: #666;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.active > a, .pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-trigger > a, .pcoded-navbar.menu-light .pcoded-inner-navbar li:hover > a {
  color: #666;
}

/* ====== [ light menu style end ] ======== */
/* ====== [ Header color start ] ======== */
.pcoded-header[class*=header-] .mobile-menu span {
  background-color: rgba(255, 255, 255, 0.8);
}
.pcoded-header[class*=header-] .mobile-menu span:after, .pcoded-header[class*=header-] .mobile-menu span:before {
  background-color: rgba(255, 255, 255, 0.8);
}
.pcoded-header[class*=header-] .mobile-menu:hover span {
  background-color: #fff;
}
.pcoded-header[class*=header-] .mobile-menu:hover span:after, .pcoded-header[class*=header-] .mobile-menu:hover span:before {
  background-color: #fff;
}
@media only screen and (max-width: 992px) {
  .pcoded-header[class*=header-] .page-header .breadcrumb-item + .breadcrumb-item::before,
  .pcoded-header[class*=header-] .page-header .page-header-title + .breadcrumb > .breadcrumb-item a,
  .pcoded-header[class*=header-] .page-header h5,
  .pcoded-header[class*=header-] .page-header .h5 {
    color: #fff;
  }
}
.pcoded-header.header-blue {
  background: linear-gradient(to right, #4099ff, #73b4ff);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-blue .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-blue .profile-notification li.active, .pcoded-header.header-blue .profile-notification li:active, .pcoded-header.header-blue .profile-notification li:focus, .pcoded-header.header-blue .profile-notification li:hover {
  background: rgba(64, 153, 255, 0.1);
}
.pcoded-header.header-blue .profile-notification li.active > a, .pcoded-header.header-blue .profile-notification li:active > a, .pcoded-header.header-blue .profile-notification li:focus > a, .pcoded-header.header-blue .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-blue .dropdown-menu {
  color: #222;
}
.pcoded-header.header-blue .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-blue .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-blue .dropdown-menu > li.active, .pcoded-header.header-blue .dropdown-menu > li:active, .pcoded-header.header-blue .dropdown-menu > li:focus, .pcoded-header.header-blue .dropdown-menu > li:hover {
  background: rgba(64, 153, 255, 0.1);
  color: #222;
}
.pcoded-header.header-blue .dropdown-menu > li.active > a, .pcoded-header.header-blue .dropdown-menu > li:active > a, .pcoded-header.header-blue .dropdown-menu > li:focus > a, .pcoded-header.header-blue .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-blue .input-group .input-group-text,
.pcoded-header.header-blue a,
.pcoded-header.header-blue dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-blue .input-group .input-group-text:hover,
.pcoded-header.header-blue a:hover,
.pcoded-header.header-blue dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .input-group .input-group-text,
  .pcoded-header.header-blue a,
  .pcoded-header.header-blue dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-blue .input-group .input-group-text:hover,
  .pcoded-header.header-blue a:hover,
  .pcoded-header.header-blue dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-blue .main-search .search-close > .input-group-text {
  color: #4099ff;
}
.pcoded-header.header-blue .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-blue .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-blue .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-blue .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-blue .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-blue .main-search.open .input-group .search-btn {
  background: #4099ff;
  border-color: #4099ff;
}
.pcoded-header.header-blue .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-blue .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-blue .dropdown .notification .noti-body li.notification:hover {
  background: rgba(64, 153, 255, 0.1);
}
.pcoded-header.header-blue .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #4099ff;
}
.pcoded-header.header-blue .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-blue .b-bg {
  background: #fff;
  color: #4099ff;
}
.pcoded-header.header-red {
  background: linear-gradient(to right, #ff5370, #f48fb1);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-red .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-red .profile-notification li.active, .pcoded-header.header-red .profile-notification li:active, .pcoded-header.header-red .profile-notification li:focus, .pcoded-header.header-red .profile-notification li:hover {
  background: rgba(255, 83, 112, 0.1);
}
.pcoded-header.header-red .profile-notification li.active > a, .pcoded-header.header-red .profile-notification li:active > a, .pcoded-header.header-red .profile-notification li:focus > a, .pcoded-header.header-red .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-red .dropdown-menu {
  color: #222;
}
.pcoded-header.header-red .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-red .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-red .dropdown-menu > li.active, .pcoded-header.header-red .dropdown-menu > li:active, .pcoded-header.header-red .dropdown-menu > li:focus, .pcoded-header.header-red .dropdown-menu > li:hover {
  background: rgba(255, 83, 112, 0.1);
  color: #222;
}
.pcoded-header.header-red .dropdown-menu > li.active > a, .pcoded-header.header-red .dropdown-menu > li:active > a, .pcoded-header.header-red .dropdown-menu > li:focus > a, .pcoded-header.header-red .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-red .input-group .input-group-text,
.pcoded-header.header-red a,
.pcoded-header.header-red dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-red .input-group .input-group-text:hover,
.pcoded-header.header-red a:hover,
.pcoded-header.header-red dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-red .input-group .input-group-text,
  .pcoded-header.header-red a,
  .pcoded-header.header-red dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-red .input-group .input-group-text:hover,
  .pcoded-header.header-red a:hover,
  .pcoded-header.header-red dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-red .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-red .main-search .search-close > .input-group-text {
  color: #ff5370;
}
.pcoded-header.header-red .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-red .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-red .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-red .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-red .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-red .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-red .main-search.open .input-group .search-btn {
  background: #ff5370;
  border-color: #ff5370;
}
.pcoded-header.header-red .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-red .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-red .dropdown .notification .noti-body li.notification:hover {
  background: rgba(255, 83, 112, 0.1);
}
.pcoded-header.header-red .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #ff5370;
}
.pcoded-header.header-red .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-red .b-bg {
  background: #fff;
  color: #ff5370;
}
.pcoded-header.header-purple {
  background: linear-gradient(to right, #4099ff, #6e7ff3);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-purple .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-purple .profile-notification li.active, .pcoded-header.header-purple .profile-notification li:active, .pcoded-header.header-purple .profile-notification li:focus, .pcoded-header.header-purple .profile-notification li:hover {
  background: rgba(64, 153, 255, 0.1);
}
.pcoded-header.header-purple .profile-notification li.active > a, .pcoded-header.header-purple .profile-notification li:active > a, .pcoded-header.header-purple .profile-notification li:focus > a, .pcoded-header.header-purple .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-purple .dropdown-menu {
  color: #222;
}
.pcoded-header.header-purple .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-purple .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-purple .dropdown-menu > li.active, .pcoded-header.header-purple .dropdown-menu > li:active, .pcoded-header.header-purple .dropdown-menu > li:focus, .pcoded-header.header-purple .dropdown-menu > li:hover {
  background: rgba(64, 153, 255, 0.1);
  color: #222;
}
.pcoded-header.header-purple .dropdown-menu > li.active > a, .pcoded-header.header-purple .dropdown-menu > li:active > a, .pcoded-header.header-purple .dropdown-menu > li:focus > a, .pcoded-header.header-purple .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-purple .input-group .input-group-text,
.pcoded-header.header-purple a,
.pcoded-header.header-purple dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-purple .input-group .input-group-text:hover,
.pcoded-header.header-purple a:hover,
.pcoded-header.header-purple dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-purple .input-group .input-group-text,
  .pcoded-header.header-purple a,
  .pcoded-header.header-purple dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-purple .input-group .input-group-text:hover,
  .pcoded-header.header-purple a:hover,
  .pcoded-header.header-purple dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-purple .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-purple .main-search .search-close > .input-group-text {
  color: #4099ff;
}
.pcoded-header.header-purple .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-purple .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-purple .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-purple .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-purple .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-purple .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-purple .main-search.open .input-group .search-btn {
  background: #4099ff;
  border-color: #4099ff;
}
.pcoded-header.header-purple .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-purple .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-purple .dropdown .notification .noti-body li.notification:hover {
  background: rgba(64, 153, 255, 0.1);
}
.pcoded-header.header-purple .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #4099ff;
}
.pcoded-header.header-purple .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-purple .b-bg {
  background: #fff;
  color: #4099ff;
}
.pcoded-header.header-info {
  background: linear-gradient(to right, #38a9f9, #43e9b3);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-info .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-info .profile-notification li.active, .pcoded-header.header-info .profile-notification li:active, .pcoded-header.header-info .profile-notification li:focus, .pcoded-header.header-info .profile-notification li:hover {
  background: rgba(56, 169, 249, 0.1);
}
.pcoded-header.header-info .profile-notification li.active > a, .pcoded-header.header-info .profile-notification li:active > a, .pcoded-header.header-info .profile-notification li:focus > a, .pcoded-header.header-info .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-info .dropdown-menu {
  color: #222;
}
.pcoded-header.header-info .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-info .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-info .dropdown-menu > li.active, .pcoded-header.header-info .dropdown-menu > li:active, .pcoded-header.header-info .dropdown-menu > li:focus, .pcoded-header.header-info .dropdown-menu > li:hover {
  background: rgba(56, 169, 249, 0.1);
  color: #222;
}
.pcoded-header.header-info .dropdown-menu > li.active > a, .pcoded-header.header-info .dropdown-menu > li:active > a, .pcoded-header.header-info .dropdown-menu > li:focus > a, .pcoded-header.header-info .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-info .input-group .input-group-text,
.pcoded-header.header-info a,
.pcoded-header.header-info dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-info .input-group .input-group-text:hover,
.pcoded-header.header-info a:hover,
.pcoded-header.header-info dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-info .input-group .input-group-text,
  .pcoded-header.header-info a,
  .pcoded-header.header-info dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-info .input-group .input-group-text:hover,
  .pcoded-header.header-info a:hover,
  .pcoded-header.header-info dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-info .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-info .main-search .search-close > .input-group-text {
  color: #38a9f9;
}
.pcoded-header.header-info .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-info .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-info .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-info .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-info .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-info .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-info .main-search.open .input-group .search-btn {
  background: #38a9f9;
  border-color: #38a9f9;
}
.pcoded-header.header-info .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-info .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-info .dropdown .notification .noti-body li.notification:hover {
  background: rgba(56, 169, 249, 0.1);
}
.pcoded-header.header-info .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #38a9f9;
}
.pcoded-header.header-info .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-info .b-bg {
  background: #fff;
  color: #38a9f9;
}
.pcoded-header.header-dark {
  background: linear-gradient(to right, #3b4650, #647688);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-dark .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-dark .profile-notification li.active, .pcoded-header.header-dark .profile-notification li:active, .pcoded-header.header-dark .profile-notification li:focus, .pcoded-header.header-dark .profile-notification li:hover {
  background: rgba(59, 70, 80, 0.1);
}
.pcoded-header.header-dark .profile-notification li.active > a, .pcoded-header.header-dark .profile-notification li:active > a, .pcoded-header.header-dark .profile-notification li:focus > a, .pcoded-header.header-dark .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-dark .dropdown-menu {
  color: #222;
}
.pcoded-header.header-dark .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-dark .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-dark .dropdown-menu > li.active, .pcoded-header.header-dark .dropdown-menu > li:active, .pcoded-header.header-dark .dropdown-menu > li:focus, .pcoded-header.header-dark .dropdown-menu > li:hover {
  background: rgba(59, 70, 80, 0.1);
  color: #222;
}
.pcoded-header.header-dark .dropdown-menu > li.active > a, .pcoded-header.header-dark .dropdown-menu > li:active > a, .pcoded-header.header-dark .dropdown-menu > li:focus > a, .pcoded-header.header-dark .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-dark .input-group .input-group-text,
.pcoded-header.header-dark a,
.pcoded-header.header-dark dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-dark .input-group .input-group-text:hover,
.pcoded-header.header-dark a:hover,
.pcoded-header.header-dark dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-dark .input-group .input-group-text,
  .pcoded-header.header-dark a,
  .pcoded-header.header-dark dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-dark .input-group .input-group-text:hover,
  .pcoded-header.header-dark a:hover,
  .pcoded-header.header-dark dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-dark .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-dark .main-search .search-close > .input-group-text {
  color: #3b4650;
}
.pcoded-header.header-dark .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-dark .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-dark .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-dark .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-dark .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-dark .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-dark .main-search.open .input-group .search-btn {
  background: #3b4650;
  border-color: #3b4650;
}
.pcoded-header.header-dark .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-dark .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-dark .dropdown .notification .noti-body li.notification:hover {
  background: rgba(59, 70, 80, 0.1);
}
.pcoded-header.header-dark .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #3b4650;
}
.pcoded-header.header-dark .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-dark .b-bg {
  background: #fff;
  color: #3b4650;
}
.pcoded-header.header-orange {
  background: linear-gradient(to right, #ff784f, #ff5370);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-orange .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-orange .profile-notification li.active, .pcoded-header.header-orange .profile-notification li:active, .pcoded-header.header-orange .profile-notification li:focus, .pcoded-header.header-orange .profile-notification li:hover {
  background: rgba(255, 120, 79, 0.1);
}
.pcoded-header.header-orange .profile-notification li.active > a, .pcoded-header.header-orange .profile-notification li:active > a, .pcoded-header.header-orange .profile-notification li:focus > a, .pcoded-header.header-orange .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-orange .dropdown-menu {
  color: #222;
}
.pcoded-header.header-orange .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-orange .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-orange .dropdown-menu > li.active, .pcoded-header.header-orange .dropdown-menu > li:active, .pcoded-header.header-orange .dropdown-menu > li:focus, .pcoded-header.header-orange .dropdown-menu > li:hover {
  background: rgba(255, 120, 79, 0.1);
  color: #222;
}
.pcoded-header.header-orange .dropdown-menu > li.active > a, .pcoded-header.header-orange .dropdown-menu > li:active > a, .pcoded-header.header-orange .dropdown-menu > li:focus > a, .pcoded-header.header-orange .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-orange .input-group .input-group-text,
.pcoded-header.header-orange a,
.pcoded-header.header-orange dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-orange .input-group .input-group-text:hover,
.pcoded-header.header-orange a:hover,
.pcoded-header.header-orange dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-orange .input-group .input-group-text,
  .pcoded-header.header-orange a,
  .pcoded-header.header-orange dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-orange .input-group .input-group-text:hover,
  .pcoded-header.header-orange a:hover,
  .pcoded-header.header-orange dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-orange .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-orange .main-search .search-close > .input-group-text {
  color: #ff784f;
}
.pcoded-header.header-orange .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-orange .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-orange .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-orange .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-orange .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-orange .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-orange .main-search.open .input-group .search-btn {
  background: #ff784f;
  border-color: #ff784f;
}
.pcoded-header.header-orange .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-orange .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-orange .dropdown .notification .noti-body li.notification:hover {
  background: rgba(255, 120, 79, 0.1);
}
.pcoded-header.header-orange .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #ff784f;
}
.pcoded-header.header-orange .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-orange .b-bg {
  background: #fff;
  color: #ff784f;
}
.pcoded-header.header-green {
  background: linear-gradient(to right, #11c788, #54e2b7);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-green .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-green .profile-notification li.active, .pcoded-header.header-green .profile-notification li:active, .pcoded-header.header-green .profile-notification li:focus, .pcoded-header.header-green .profile-notification li:hover {
  background: rgba(17, 199, 136, 0.1);
}
.pcoded-header.header-green .profile-notification li.active > a, .pcoded-header.header-green .profile-notification li:active > a, .pcoded-header.header-green .profile-notification li:focus > a, .pcoded-header.header-green .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-green .dropdown-menu {
  color: #222;
}
.pcoded-header.header-green .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-green .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-green .dropdown-menu > li.active, .pcoded-header.header-green .dropdown-menu > li:active, .pcoded-header.header-green .dropdown-menu > li:focus, .pcoded-header.header-green .dropdown-menu > li:hover {
  background: rgba(17, 199, 136, 0.1);
  color: #222;
}
.pcoded-header.header-green .dropdown-menu > li.active > a, .pcoded-header.header-green .dropdown-menu > li:active > a, .pcoded-header.header-green .dropdown-menu > li:focus > a, .pcoded-header.header-green .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-green .input-group .input-group-text,
.pcoded-header.header-green a,
.pcoded-header.header-green dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-green .input-group .input-group-text:hover,
.pcoded-header.header-green a:hover,
.pcoded-header.header-green dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-green .input-group .input-group-text,
  .pcoded-header.header-green a,
  .pcoded-header.header-green dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-green .input-group .input-group-text:hover,
  .pcoded-header.header-green a:hover,
  .pcoded-header.header-green dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-green .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-green .main-search .search-close > .input-group-text {
  color: #11c788;
}
.pcoded-header.header-green .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-green .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-green .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-green .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-green .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-green .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-green .main-search.open .input-group .search-btn {
  background: #11c788;
  border-color: #11c788;
}
.pcoded-header.header-green .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-green .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-green .dropdown .notification .noti-body li.notification:hover {
  background: rgba(17, 199, 136, 0.1);
}
.pcoded-header.header-green .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #11c788;
}
.pcoded-header.header-green .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-green .b-bg {
  background: #fff;
  color: #11c788;
}
.pcoded-header.header-yellow {
  background: linear-gradient(to right, #ff5858, #f09819);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-yellow .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-yellow .profile-notification li.active, .pcoded-header.header-yellow .profile-notification li:active, .pcoded-header.header-yellow .profile-notification li:focus, .pcoded-header.header-yellow .profile-notification li:hover {
  background: rgba(255, 88, 88, 0.1);
}
.pcoded-header.header-yellow .profile-notification li.active > a, .pcoded-header.header-yellow .profile-notification li:active > a, .pcoded-header.header-yellow .profile-notification li:focus > a, .pcoded-header.header-yellow .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-yellow .dropdown-menu {
  color: #222;
}
.pcoded-header.header-yellow .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-yellow .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-yellow .dropdown-menu > li.active, .pcoded-header.header-yellow .dropdown-menu > li:active, .pcoded-header.header-yellow .dropdown-menu > li:focus, .pcoded-header.header-yellow .dropdown-menu > li:hover {
  background: rgba(255, 88, 88, 0.1);
  color: #222;
}
.pcoded-header.header-yellow .dropdown-menu > li.active > a, .pcoded-header.header-yellow .dropdown-menu > li:active > a, .pcoded-header.header-yellow .dropdown-menu > li:focus > a, .pcoded-header.header-yellow .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-yellow .input-group .input-group-text,
.pcoded-header.header-yellow a,
.pcoded-header.header-yellow dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-yellow .input-group .input-group-text:hover,
.pcoded-header.header-yellow a:hover,
.pcoded-header.header-yellow dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-yellow .input-group .input-group-text,
  .pcoded-header.header-yellow a,
  .pcoded-header.header-yellow dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-yellow .input-group .input-group-text:hover,
  .pcoded-header.header-yellow a:hover,
  .pcoded-header.header-yellow dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-yellow .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-yellow .main-search .search-close > .input-group-text {
  color: #ff5858;
}
.pcoded-header.header-yellow .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-yellow .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-yellow .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-yellow .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-yellow .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-yellow .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-yellow .main-search.open .input-group .search-btn {
  background: #ff5858;
  border-color: #ff5858;
}
.pcoded-header.header-yellow .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-yellow .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-yellow .dropdown .notification .noti-body li.notification:hover {
  background: rgba(255, 88, 88, 0.1);
}
.pcoded-header.header-yellow .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #ff5858;
}
.pcoded-header.header-yellow .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-yellow .b-bg {
  background: #fff;
  color: #ff5858;
}
.pcoded-header.header-orchidGreen {
  background: linear-gradient(to right, #39adb5, #7c4dff);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-orchidGreen .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-orchidGreen .profile-notification li.active, .pcoded-header.header-orchidGreen .profile-notification li:active, .pcoded-header.header-orchidGreen .profile-notification li:focus, .pcoded-header.header-orchidGreen .profile-notification li:hover {
  background: rgba(57, 173, 181, 0.1);
}
.pcoded-header.header-orchidGreen .profile-notification li.active > a, .pcoded-header.header-orchidGreen .profile-notification li:active > a, .pcoded-header.header-orchidGreen .profile-notification li:focus > a, .pcoded-header.header-orchidGreen .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-orchidGreen .dropdown-menu {
  color: #222;
}
.pcoded-header.header-orchidGreen .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-orchidGreen .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-orchidGreen .dropdown-menu > li.active, .pcoded-header.header-orchidGreen .dropdown-menu > li:active, .pcoded-header.header-orchidGreen .dropdown-menu > li:focus, .pcoded-header.header-orchidGreen .dropdown-menu > li:hover {
  background: rgba(57, 173, 181, 0.1);
  color: #222;
}
.pcoded-header.header-orchidGreen .dropdown-menu > li.active > a, .pcoded-header.header-orchidGreen .dropdown-menu > li:active > a, .pcoded-header.header-orchidGreen .dropdown-menu > li:focus > a, .pcoded-header.header-orchidGreen .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-orchidGreen .input-group .input-group-text,
.pcoded-header.header-orchidGreen a,
.pcoded-header.header-orchidGreen dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-orchidGreen .input-group .input-group-text:hover,
.pcoded-header.header-orchidGreen a:hover,
.pcoded-header.header-orchidGreen dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-orchidGreen .input-group .input-group-text,
  .pcoded-header.header-orchidGreen a,
  .pcoded-header.header-orchidGreen dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-orchidGreen .input-group .input-group-text:hover,
  .pcoded-header.header-orchidGreen a:hover,
  .pcoded-header.header-orchidGreen dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-orchidGreen .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-orchidGreen .main-search .search-close > .input-group-text {
  color: #39adb5;
}
.pcoded-header.header-orchidGreen .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-orchidGreen .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-orchidGreen .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-orchidGreen .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-orchidGreen .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-orchidGreen .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-orchidGreen .main-search.open .input-group .search-btn {
  background: #39adb5;
  border-color: #39adb5;
}
.pcoded-header.header-orchidGreen .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-orchidGreen .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-orchidGreen .dropdown .notification .noti-body li.notification:hover {
  background: rgba(57, 173, 181, 0.1);
}
.pcoded-header.header-orchidGreen .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #39adb5;
}
.pcoded-header.header-orchidGreen .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-orchidGreen .b-bg {
  background: #fff;
  color: #39adb5;
}
.pcoded-header.header-indigoGreen {
  background: linear-gradient(to right, #473b7b, #30d2be);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-indigoGreen .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-indigoGreen .profile-notification li.active, .pcoded-header.header-indigoGreen .profile-notification li:active, .pcoded-header.header-indigoGreen .profile-notification li:focus, .pcoded-header.header-indigoGreen .profile-notification li:hover {
  background: rgba(71, 59, 123, 0.1);
}
.pcoded-header.header-indigoGreen .profile-notification li.active > a, .pcoded-header.header-indigoGreen .profile-notification li:active > a, .pcoded-header.header-indigoGreen .profile-notification li:focus > a, .pcoded-header.header-indigoGreen .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-indigoGreen .dropdown-menu {
  color: #222;
}
.pcoded-header.header-indigoGreen .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-indigoGreen .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-indigoGreen .dropdown-menu > li.active, .pcoded-header.header-indigoGreen .dropdown-menu > li:active, .pcoded-header.header-indigoGreen .dropdown-menu > li:focus, .pcoded-header.header-indigoGreen .dropdown-menu > li:hover {
  background: rgba(71, 59, 123, 0.1);
  color: #222;
}
.pcoded-header.header-indigoGreen .dropdown-menu > li.active > a, .pcoded-header.header-indigoGreen .dropdown-menu > li:active > a, .pcoded-header.header-indigoGreen .dropdown-menu > li:focus > a, .pcoded-header.header-indigoGreen .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-indigoGreen .input-group .input-group-text,
.pcoded-header.header-indigoGreen a,
.pcoded-header.header-indigoGreen dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-indigoGreen .input-group .input-group-text:hover,
.pcoded-header.header-indigoGreen a:hover,
.pcoded-header.header-indigoGreen dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-indigoGreen .input-group .input-group-text,
  .pcoded-header.header-indigoGreen a,
  .pcoded-header.header-indigoGreen dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-indigoGreen .input-group .input-group-text:hover,
  .pcoded-header.header-indigoGreen a:hover,
  .pcoded-header.header-indigoGreen dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-indigoGreen .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-indigoGreen .main-search .search-close > .input-group-text {
  color: #473b7b;
}
.pcoded-header.header-indigoGreen .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-indigoGreen .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-indigoGreen .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-indigoGreen .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-indigoGreen .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-indigoGreen .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-indigoGreen .main-search.open .input-group .search-btn {
  background: #473b7b;
  border-color: #473b7b;
}
.pcoded-header.header-indigoGreen .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-indigoGreen .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-indigoGreen .dropdown .notification .noti-body li.notification:hover {
  background: rgba(71, 59, 123, 0.1);
}
.pcoded-header.header-indigoGreen .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #473b7b;
}
.pcoded-header.header-indigoGreen .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-indigoGreen .b-bg {
  background: #fff;
  color: #473b7b;
}
.pcoded-header.header-darkGreen {
  background: linear-gradient(to right, #000000, #0fb3c2);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-darkGreen .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-darkGreen .profile-notification li.active, .pcoded-header.header-darkGreen .profile-notification li:active, .pcoded-header.header-darkGreen .profile-notification li:focus, .pcoded-header.header-darkGreen .profile-notification li:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-header.header-darkGreen .profile-notification li.active > a, .pcoded-header.header-darkGreen .profile-notification li:active > a, .pcoded-header.header-darkGreen .profile-notification li:focus > a, .pcoded-header.header-darkGreen .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-darkGreen .dropdown-menu {
  color: #222;
}
.pcoded-header.header-darkGreen .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-darkGreen .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-darkGreen .dropdown-menu > li.active, .pcoded-header.header-darkGreen .dropdown-menu > li:active, .pcoded-header.header-darkGreen .dropdown-menu > li:focus, .pcoded-header.header-darkGreen .dropdown-menu > li:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #222;
}
.pcoded-header.header-darkGreen .dropdown-menu > li.active > a, .pcoded-header.header-darkGreen .dropdown-menu > li:active > a, .pcoded-header.header-darkGreen .dropdown-menu > li:focus > a, .pcoded-header.header-darkGreen .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-darkGreen .input-group .input-group-text,
.pcoded-header.header-darkGreen a,
.pcoded-header.header-darkGreen dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-darkGreen .input-group .input-group-text:hover,
.pcoded-header.header-darkGreen a:hover,
.pcoded-header.header-darkGreen dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-darkGreen .input-group .input-group-text,
  .pcoded-header.header-darkGreen a,
  .pcoded-header.header-darkGreen dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-darkGreen .input-group .input-group-text:hover,
  .pcoded-header.header-darkGreen a:hover,
  .pcoded-header.header-darkGreen dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-darkGreen .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-darkGreen .main-search .search-close > .input-group-text {
  color: #000000;
}
.pcoded-header.header-darkGreen .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-darkGreen .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-darkGreen .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-darkGreen .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-darkGreen .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-darkGreen .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-darkGreen .main-search.open .input-group .search-btn {
  background: #000000;
  border-color: #000000;
}
.pcoded-header.header-darkGreen .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-darkGreen .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-darkGreen .dropdown .notification .noti-body li.notification:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-header.header-darkGreen .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #000000;
}
.pcoded-header.header-darkGreen .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-darkGreen .b-bg {
  background: #fff;
  color: #000000;
}
.pcoded-header.header-darkblue {
  background: linear-gradient(to right, #2d0102, #091991);
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-darkblue .profile-notification li > a {
  color: #222;
}
.pcoded-header.header-darkblue .profile-notification li.active, .pcoded-header.header-darkblue .profile-notification li:active, .pcoded-header.header-darkblue .profile-notification li:focus, .pcoded-header.header-darkblue .profile-notification li:hover {
  background: rgba(45, 1, 2, 0.1);
}
.pcoded-header.header-darkblue .profile-notification li.active > a, .pcoded-header.header-darkblue .profile-notification li:active > a, .pcoded-header.header-darkblue .profile-notification li:focus > a, .pcoded-header.header-darkblue .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-darkblue .dropdown-menu {
  color: #222;
}
.pcoded-header.header-darkblue .dropdown-menu a {
  color: #222;
}
.pcoded-header.header-darkblue .dropdown-menu > li > a {
  color: #222;
}
.pcoded-header.header-darkblue .dropdown-menu > li.active, .pcoded-header.header-darkblue .dropdown-menu > li:active, .pcoded-header.header-darkblue .dropdown-menu > li:focus, .pcoded-header.header-darkblue .dropdown-menu > li:hover {
  background: rgba(45, 1, 2, 0.1);
  color: #222;
}
.pcoded-header.header-darkblue .dropdown-menu > li.active > a, .pcoded-header.header-darkblue .dropdown-menu > li:active > a, .pcoded-header.header-darkblue .dropdown-menu > li:focus > a, .pcoded-header.header-darkblue .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-darkblue .input-group .input-group-text,
.pcoded-header.header-darkblue a,
.pcoded-header.header-darkblue dropdown-toggle {
  color: #fff;
}
.pcoded-header.header-darkblue .input-group .input-group-text:hover,
.pcoded-header.header-darkblue a:hover,
.pcoded-header.header-darkblue dropdown-toggle:hover {
  color: #666;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-darkblue .input-group .input-group-text,
  .pcoded-header.header-darkblue a,
  .pcoded-header.header-darkblue dropdown-toggle {
    color: #666;
  }
  .pcoded-header.header-darkblue .input-group .input-group-text:hover,
  .pcoded-header.header-darkblue a:hover,
  .pcoded-header.header-darkblue dropdown-toggle:hover {
    color: #4d4d4d;
  }
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-darkblue .m-header > a {
    color: #fff;
  }
}
.pcoded-header.header-darkblue .main-search .search-close > .input-group-text {
  color: #2d0102;
}
.pcoded-header.header-darkblue .main-search.open .input-group {
  background: rgba(255, 255, 255, 0.25);
  border: 0 solid rgba(255, 255, 255, 0.25);
  padding: 2px 2px 2px 20px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-darkblue .main-search.open .input-group {
    background: #f6f7fb;
  }
}
@media only screen and (min-width: 992px) {
  .pcoded-header.header-darkblue .main-search.open .input-group .form-control {
    color: #fff;
  }
  .pcoded-header.header-darkblue .main-search.open .input-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  .pcoded-header.header-darkblue .main-search.open .input-group .form-control:-ms-input-placeholder {
    color: #fff;
  }
  .pcoded-header.header-darkblue .main-search.open .input-group .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}
.pcoded-header.header-darkblue .main-search.open .input-group .search-btn {
  background: #2d0102;
  border-color: #2d0102;
}
.pcoded-header.header-darkblue .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-darkblue .dropdown .notification .noti-head a {
  color: #fff;
}
.pcoded-header.header-darkblue .dropdown .notification .noti-body li.notification:hover {
  background: rgba(45, 1, 2, 0.1);
}
.pcoded-header.header-darkblue .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #2d0102;
}
.pcoded-header.header-darkblue .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-darkblue .b-bg {
  background: #fff;
  color: #2d0102;
}

/* ====== [ Header color end ] ======== */
/* ====== [ menu fixed start ] ======== */
.pcoded-navbar.menupos-fixed {
  position: fixed;
  height: 100vh;
  min-height: 100vh;
}
.pcoded-navbar.menupos-fixed .scroll-div.navbar-content {
  height: calc(100% - 56px);
  min-height: calc(100% - 56px);
}

/* ====== [ menu fixed end ] ======== */
/* ====== [ Header Fixed start ] ======== */
.pcoded-header.headerPos-fixed {
  position: fixed;
  top: 0;
}
.pcoded-header.headerPos-fixed ~ .pcoded-main-container {
  padding-top: 56px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.headerPos-fixed ~ .pcoded-main-container {
    padding-top: 56px;
  }
}

/* ====== [Header Fixed  end ] ======== */
/* ====== [ Box end ] ======== */
.pcoded-navbar .pcoded-inner-navbar li.active > a, .pcoded-navbar .pcoded-inner-navbar li:focus > a, .pcoded-navbar .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > app-nav-item > li.active > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > app-nav-item > li:focus > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > app-nav-item > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption:first-child {
  margin-top: 10px;
  border-top: 1px solid #344258;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu:after {
  height: calc(100% - 30px);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > app-nav-item > li > a:before {
  left: 65px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > app-nav-item > li:after {
  left: 45px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu:after {
  height: calc(100% - 15px);
  top: -15px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.pcoded-trigger > a:before, .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li:hover > a:before, .pcoded .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  background-color: #4099ff;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > app-nav-item > li > a {
  padding: 7px 7px 7px 80px;
}
.pcoded-navbar .pcoded-inner-navbar app-nav-group:first-child > .pcoded-menu-caption {
  border-top: none;
  margin-top: 0;
}
.pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-collapse > li.active > a, .pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-collapse > li.pcoded-trigger > a,
.pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-item > li.active > a,
.pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-item > li.pcoded-trigger > a {
  color: #fff;
}
.pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-collapse > li.pcoded-trigger > a, .pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-collapse > li:hover > a, .pcoded .pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-collapse > li.active > a,
.pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-item > li.pcoded-trigger > a,
.pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-item > li:hover > a,
.pcoded .pcoded-navbar .pcoded-inner-navbar app-nav-group > app-nav-item > li.active > a {
  color: #fff;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar .pcoded-menu-caption:after {
  width: 40px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li > a,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li > a {
  padding: 7px 20px;
  width: 70px;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li > a > .pcoded-micon + .pcoded-mtext,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li > a > .pcoded-micon + .pcoded-mtext {
  position: absolute;
  top: 11px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li > a > .pcoded-mtext,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li > a > .pcoded-mtext {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-hasmenu > a:after,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-hasmenu > a:after {
  right: 12px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-hasmenu .pcoded-submenu,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger .pcoded-submenu li a,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger .pcoded-submenu li a {
  color: transparent;
  white-space: nowrap;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li > a,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li > a {
  width: 100%;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li > a > .pcoded-mtext,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li > a > .pcoded-mtext {
  transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li > a > .pcoded-mtext:after,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li > a > .pcoded-mtext:after {
  opacity: 1;
  visibility: visible;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-hasmenu > a:after,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-hasmenu > a:after {
  transform: rotateX(0deg);
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-hasmenu.pcoded-trigger > a:after,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-hasmenu.pcoded-trigger > a:after {
  transform: rotateX(0deg) rotate(90deg);
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger .pcoded-submenu li a,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger .pcoded-submenu li a {
  color: inherit;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before,
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 1;
}

@media only screen and (max-width: 991px) {
  .pcoded-header .container > .collapse:not(.show) .me-auto,
  .pcoded-header > .collapse:not(.show) .me-auto {
    display: inline-flex;
    justify-content: center;
  }
}
app-nav-group app-nav-collapse > li {
  overflow: hidden;
}
app-nav-group app-nav-collapse > li > a {
  z-index: 999;
}
app-nav-group > app-nav-collapse > li {
  overflow: hidden;
}
app-nav-group > app-nav-collapse > li > a {
  z-index: 999;
}

.navbar-collapsed:hover .mobile-menu {
  transform: rotateY(0deg) !important;
  opacity: 1 !important;
}

.brand-default .b-brand .b-bg {
  color: #fff;
}
.brand-default .b-brand .b-bg i {
  color: #fff;
  background-image: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar app-nav-item > li.nav-item.active > a,
.pcoded-navbar.menu-light .pcoded-inner-navbar app-nav-item > li.nav-item.pcoded-trigger > a {
  background: #f1f1f1;
  color: #000;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li.pcoded-menu-caption:first-child {
  border-top-color: #e2e5e8;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-hasmenu .pcoded-submenu,
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar app-nav-item > li.pcoded-hasmenu .pcoded-submenu {
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 50%;
  transition: transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  position: absolute;
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  position: relative;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}
.pcoded-navbar.menupos-fixed {
  height: 100vh;
  min-height: 100vh;
}
.pcoded-navbar.menupos-fixed .pcoded-inner-navbar {
  margin-bottom: 56px;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a,
.pcoded-navbar.menu-light .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active a,
.pcoded-navbar.menu-light .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a,
.pcoded-navbar.menu-light .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger a,
.pcoded-navbar.menu-light .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li:hover a,
.pcoded-navbar.menu-light .pcoded-inner-navbar app-nav-item > li.active a,
.pcoded-navbar.menu-light .pcoded-inner-navbar app-nav-item > li.pcoded-trigger a,
.pcoded-navbar.menu-light .pcoded-inner-navbar app-nav-item > li:hover a {
  background: transparent;
}

.pcoded-navbar[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #4099ff;
}
.pcoded-navbar.active-blue[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #4099ff;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse li.active > a, .pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:focus > a, .pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:hover > a {
  color: #4099ff;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li.active > a:before, .pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:focus > a:before, .pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:hover > a:before {
  background: #4099ff;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active:after, .pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger:after {
  background-color: #4099ff;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a, .pcoded-navbar.active-blue .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a {
  background: #2e373e;
  color: #fff;
}
.pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #000;
}
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #4099ff;
}
.pcoded-navbar[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #4099ff;
}
.pcoded-navbar.active-red[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #ff5370;
}
.pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse li.active > a, .pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:focus > a, .pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:hover > a {
  color: #ff5370;
}
.pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li.active > a:before, .pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:focus > a:before, .pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:hover > a:before {
  background: #ff5370;
}
.pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active:after, .pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger:after {
  background-color: #ff5370;
}
.pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a, .pcoded-navbar.active-red .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a {
  background: #2e373e;
  color: #fff;
}
.pcoded-navbar.active-red.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #000;
}
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #ff5370;
}
.pcoded-navbar[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #4099ff;
}
.pcoded-navbar.active-purple[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #7759de;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse li.active > a, .pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:focus > a, .pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:hover > a {
  color: #7759de;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li.active > a:before, .pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:focus > a:before, .pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:hover > a:before {
  background: #7759de;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active:after, .pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger:after {
  background-color: #7759de;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a, .pcoded-navbar.active-purple .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a {
  background: #2e373e;
  color: #fff;
}
.pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #000;
}
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #7759de;
}
.pcoded-navbar[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #4099ff;
}
.pcoded-navbar.active-info[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #00bcd4;
}
.pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse li.active > a, .pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:focus > a, .pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:hover > a {
  color: #00bcd4;
}
.pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li.active > a:before, .pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:focus > a:before, .pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:hover > a:before {
  background: #00bcd4;
}
.pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active:after, .pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger:after {
  background-color: #00bcd4;
}
.pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a, .pcoded-navbar.active-info .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a {
  background: #2e373e;
  color: #fff;
}
.pcoded-navbar.active-info.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-info.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #000;
}
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #00bcd4;
}
.pcoded-navbar[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #4099ff;
}
.pcoded-navbar.active-dark[class*=menu-item-icon-style] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #263238;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse li.active > a, .pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:focus > a, .pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse li:hover > a {
  color: #263238;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li.active > a:before, .pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:focus > a:before, .pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse li .pcoded-submenu > app-nav-item li:hover > a:before {
  background: #263238;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active:after, .pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger:after {
  background-color: #263238;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a, .pcoded-navbar.active-dark .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a {
  background: #2e373e;
  color: #fff;
}
.pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #000;
}
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #263238;
}

.order-card {
  color: #fff;
}
.order-card i {
  font-size: 26px;
}

.seo-card .seo-chart {
  margin-top: 60px;
}
.seo-card .seo-statustic {
  position: absolute;
}

.feed-blog {
  border-left: 1px solid #d6d6d6;
  margin-left: 30px;
}
.feed-blog li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 50px;
}
.feed-blog li h6, .feed-blog li .h6 {
  line-height: 1.5;
  cursor: pointer;
}
.feed-blog li .feed-user-img {
  position: absolute;
  left: -20px;
  top: -10px;
}
.feed-blog li .feed-user-img img {
  width: 40px;
  height: 40px;
}
.feed-blog li .feed-user-img:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  border: 3px solid transparent;
  border-radius: 50%;
}
.feed-blog li.active-feed .feed-user-img:after {
  border-color: #2ed8b6;
}
.feed-blog li.diactive-feed .feed-user-img:after {
  border-color: #ff5370;
}

/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: linear-gradient(45deg, #4099ff, #82bcff);
}
@media (min-width: 1200px) {
  .auth-wrapper .container {
    max-width: 1140px;
  }
}
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper {
    display: block;
    margin: 0 auto;
  }
  .auth-wrapper > .row {
    min-height: 100vh;
  }
  .auth-wrapper > .row > .aut-bg-img {
    min-height: 100vh;
  }
}
.auth-wrapper .saprator {
  position: relative;
  margin: 8px 0;
}
.auth-wrapper .saprator span {
  background: #fff;
  position: relative;
  padding: 0 10px;
  z-index: 5;
  font-size: 20px;
}
.auth-wrapper .saprator:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e2e5e8;
  z-index: 1;
}
.auth-wrapper a,
.auth-wrapper p > a {
  color: #222;
  font-weight: 600;
}
.auth-wrapper .input-group {
  background: transparent;
}
.auth-wrapper .card {
  margin-bottom: 0;
  padding: 8px;
  box-shadow: 0 2px 18px -2px rgba(0, 0, 0, 0.5);
}
.auth-wrapper .card .card-body {
  padding: 20px 25px 20px 40px;
}
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper .card {
    display: block;
  }
}
.auth-wrapper > div {
  z-index: 5;
}
.auth-wrapper .auth-content {
  position: relative;
  padding: 15px;
  z-index: 5;
}
.auth-wrapper .auth-content:not(.container) {
  width: 400px;
}
.auth-wrapper .auth-content:not(.container) .card-body {
  padding: 40px 35px;
}
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper .auth-content {
    margin: 0 auto;
  }
}
.auth-wrapper .auth-side-img {
  padding-right: 400px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-side-img {
    display: none;
  }
}
.auth-wrapper .auth-side-form {
  position: relative;
  min-height: 100%;
  background: #fff;
  display: inline-flex;
  align-items: center;
  left: auto;
  float: right;
  box-shadow: -2px 0 7px -2px rgba(0, 0, 0, 0.5);
}
.auth-wrapper .auth-side-form > * {
  position: relative;
  z-index: 5;
}
@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-side-form {
    width: 100%;
    justify-content: center;
  }
  .auth-wrapper .auth-side-form .auth-content:not(.container) {
    max-width: 350px;
  }
}
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper.aut-bg-img .auth-side-form {
    height: 100vh;
  }
}
@media only screen and (max-width: 767px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}
.auth-wrapper.offline {
  background-image: none;
}
.auth-wrapper.offline h1, .auth-wrapper.offline .h1 {
  font-size: 87px;
  font-weight: 700;
}
.auth-wrapper.offline:before {
  display: none;
}
.auth-wrapper .card-body .carousel-indicators {
  margin: 15px 0 10px;
  bottom: 0;
}
.auth-wrapper .card-body .carousel-indicators li {
  width: 50px;
  background-color: rgba(64, 153, 255, 0.4);
  border-radius: 5px;
  height: 4px;
}
.auth-wrapper .card-body .carousel-indicators li.active {
  background-color: #4099ff;
}
.auth-wrapper .img-logo-overlay {
  position: absolute;
  top: 40px;
  left: 50px;
}

/* image variant start */
.aut-bg-img {
  background-image: url("./../../../assets/images/auth/img-auth-big.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.aut-bg-img .auth-content {
  padding: 70px 40px;
}

/* image variant End */
/**====== Authentication css end ======**/
/**  =====================
      Chatting css start
==========================  **/
.header-chat,
.header-user-list {
  height: 100%;
  width: 300px;
  position: fixed;
  top: 0;
  right: -300px;
  border-radius: 0;
  z-index: 1030;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.header-chat .main-friend-cont,
.header-user-list .main-friend-cont {
  height: calc(100vh - 60px);
  position: relative;
}
.header-chat .h-list-header + .h-list-body > .main-friend-cont,
.header-user-list .h-list-header + .h-list-body > .main-friend-cont {
  height: calc(100vh - 130px);
  position: relative;
}
.header-chat .h-list-header,
.header-user-list .h-list-header {
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
}
.header-chat.open,
.header-user-list.open {
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  right: 0;
}

.header-user-list .nav-tabs {
  border-bottom: 1px solid #f1f1f1;
}
.header-user-list .nav-tabs .nav-item {
  width: 33.3333333333%;
  text-align: center;
}
.header-user-list .nav-tabs .nav-item a {
  padding: 20px 0;
  position: relative;
  background-color: transparent;
  background-image: linear-gradient(#4099ff, #4099ff);
  background-size: 0% 2px;
  background-position: bottom;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.header-user-list .nav-tabs .nav-item a.active {
  box-shadow: none;
  background-size: 100% 2px;
  color: #4099ff;
}
.header-user-list .tab-content {
  padding: 0;
  box-shadow: none;
}

.header-user-list.open .h-close-text {
  position: absolute;
  top: 35px;
  left: -30px;
}
.header-user-list.open .h-close-text i {
  position: absolute;
  top: 55px;
  left: 7px;
  font-size: 20px;
  z-index: 1003;
  color: #fff;
}
.header-user-list.open .h-close-text:after {
  content: "";
  z-index: 1001;
  position: absolute;
  top: 45px;
  left: -2px;
  background: #4099ff;
  width: 32px;
  height: 40px;
  box-shadow: -5px 2px 15px -4px rgba(69, 90, 100, 0.5);
  border-radius: 20px 0 0 20px;
}
.header-user-list.open.msg-open:after {
  color: rgba(64, 153, 255, 0.1);
}

.h-list-header .form-control:active, .h-list-header .form-control:focus, .h-list-header .form-control:hover {
  box-shadow: none;
  outline: none;
}

.h-list-body {
  position: relative;
}
.h-list-body .userlist-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  position: relative;
}
.h-list-body .userlist-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background: #f3f4f9;
}
.h-list-body .userlist-box.active {
  background: white;
}
.h-list-body .userlist-box .media-left {
  padding-right: 10px;
}
.h-list-body .userlist-box .media-object {
  width: 50px;
  display: inline-block;
}
.h-list-body .userlist-box .chat-header {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.h-list-body .userlist-box .chat-header small, .h-list-body .userlist-box .chat-header .small {
  margin-top: 5px;
  font-size: 90%;
}
.h-list-body .userlist-box .live-status {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 35px;
  right: 20px;
  border-radius: 100%;
  color: #fff;
  padding: 2px 0;
  text-align: center;
  background: #4099ff;
}

.header-chat .h-list-header {
  text-align: center;
  position: relative;
}
.header-chat .h-list-header h6, .header-chat .h-list-header .h6 {
  margin: 5px 0;
}
.header-chat .h-list-header .h-back-user-list {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  font-size: 20px;
}
.header-chat .main-chat-cont {
  height: calc(100vh - 166px);
}
.header-chat .h-list-body {
  height: 100%;
  background: white;
}
.header-chat .h-list-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  padding: 20px 15px;
  z-index: 10;
  background: white;
}
.header-chat .h-list-footer .input-group {
  background: #fff;
  border: none;
  display: inline-flex;
  box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
  padding: 7px;
  border-radius: 20px;
  width: calc(100% - 40px);
}
.header-chat .h-list-footer .input-group .form-control,
.header-chat .h-list-footer .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
.header-chat .h-list-footer .input-group .btn-send:active, .header-chat .h-list-footer .input-group .btn-send:focus, .header-chat .h-list-footer .input-group .btn-send:hover,
.header-chat .h-list-footer .input-group .form-control:active,
.header-chat .h-list-footer .input-group .form-control:focus,
.header-chat .h-list-footer .input-group .form-control:hover {
  outline: none;
  box-shadow: none;
}
.header-chat .h-list-footer .input-group .btn-attach {
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}
.header-chat .h-list-footer .input-group .btn-attach > i {
  margin-right: 0;
}
.header-chat .h-list-footer .input-group .btn-send {
  border-radius: 50%;
  padding: 10px;
  margin-left: 5px;
  position: absolute;
  right: -45px;
  top: 2px;
  z-index: 99;
}
.header-chat .h-list-footer .input-group .btn-send i {
  margin-right: 0;
}
.header-chat .h-list-footer .input-group .btn-send .input-group-text {
  color: #fff;
}
.header-chat .h-list-footer .input-group .form-control {
  transition: all 0.3s ease-in-out;
  width: 0;
}

.h-list-body .chat-messages {
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.h-list-body .chat-messages .photo-table {
  padding-right: 15px;
}
.h-list-body .chat-messages .photo-table img {
  display: inline-block;
  width: 45px;
  margin-bottom: 5px;
}
.h-list-body .chat-messages .chat-menu-content > div,
.h-list-body .chat-messages .chat-menu-reply > div {
  position: relative;
  overflow: visible;
  display: inline-block;
}
.h-list-body .chat-messages .chat-menu-content > div .chat-cont,
.h-list-body .chat-messages .chat-menu-reply > div .chat-cont {
  padding: 8px 20px;
  display: inline-block;
}
.h-list-body .chat-messages .chat-menu-content .chat-time,
.h-list-body .chat-messages .chat-menu-reply .chat-time {
  margin: 5px 24px 0 0;
}
.h-list-body .chat-messages .chat-menu-reply {
  text-align: right;
}
.h-list-body .chat-messages .chat-menu-reply > div {
  margin-top: 10px;
}
.h-list-body .chat-messages .chat-menu-reply > div p {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 4px;
  margin-right: 25px;
  box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
}
.h-list-body .chat-messages .chat-menu-reply > div:before {
  content: "";
  z-index: 1001;
  transform: rotate(-45deg);
  border: 6px solid transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 16px;
  right: 19px;
  box-shadow: 1px 5px 10px -3px rgba(62, 57, 107, 0.15);
}
.h-list-body .chat-messages .chat-menu-content > div {
  margin-top: 10px;
}
.h-list-body .chat-messages .chat-menu-content > div p {
  background: #4099ff;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 4px;
  box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
}
.h-list-body .chat-messages .chat-menu-content > div:before {
  content: "";
  z-index: 1001;
  transform: rotate(-45deg);
  border: 6px solid transparent;
  border-left-color: #4099ff;
  border-top-color: #4099ff;
  position: absolute;
  top: 12px;
  left: -6px;
  box-shadow: -4px 0 8px -5px rgba(62, 57, 107, 0.3);
}

/* massage page start */
.msg-card .msg-user-list {
  height: calc(100vh - 300px);
}
.msg-card .msg-user-chat {
  background: white;
  height: calc(100vh - 330px);
  padding: 25px 5px;
}
.msg-card .msg-block > .row > div:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #f1f1f1;
  position: absolute;
  top: 0;
}
.msg-card .msg-block > .row > div:first-child:before {
  right: 0;
}
.msg-card .msg-block > .row > div:last-child:before {
  left: -1px;
}

.main-friend-chat {
  padding-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .msg-card .msg-block > .row > div:before {
    background: none;
  }
}
/* massage page end */
/**====== Chat css end ======**/
.btn-page .btn {
  margin-right: 8px;
}

.tab-card .nav-tabs {
  margin-bottom: 1rem;
}
.tab-card .nav-pills {
  margin-bottom: 1rem;
}
.tab-card .nav-pills .nav-item {
  text-align: center;
}

.hidden {
  display: none;
}

.table td,
.table th {
  font-family: "Poppins", sans-serif;
}

.pcoded-header .search-bar {
  display: block;
}

.h-list-body .chat-messages .chat-menu-reply > div p,
.pcoded-header.header-blue a.h-back-user-list {
  color: #222;
}

.header-chat .h-list-footer .input-group .form-control {
  height: auto;
}

.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li:hover > a,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-item > li.active > a,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger > a,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-item > li:hover > a {
  background: rgba(202, 202, 202, 0.3);
}
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a:before,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a:before,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li:hover > a:before,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-item > li.active > a:before,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger > a:before,
.pcoded-navbar.menu-light:not(.theme-horizontal) .pcoded-inner-navbar > app-nav-group > app-nav-item > li:hover > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: #4099ff;
}

.flex {
  flex: 1 1 auto;
  text-align: center;
}

.custom-stepper ol.steps {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}

.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.toast-container {
  position: fixed !important;
}

.ag-watermark {
  display: none;
}

.slick-pagination .slick-pagination-settings {
  z-index: 999;
  position: relative;
  margin-right: 20px;
}

.slickgrid-container .slick-row .slick-cell.active {
  border: none !important;
  border-top: 1px solid #ddd !important;
  box-shadow: none !important;
  padding: 5px 10px !important;
}

body .slickgrid-container .grid-canvas .slick-cell {
  padding: 5px 10px !important;
  line-height: 30px;
}

.sold-out-row {
  background-color: rgba(220, 20, 60, 0.1) !important;
}

/* Core Grid CSS */
/* Quartz Theme Specific CSS */
/* ngx country drop down list CSS */
/* Angular Slickgrid CSS */
.transparent-modal .modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.ag-theme-quartz {
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
}